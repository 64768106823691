import React from 'react';

import { Box, Button, Typography } from '@mui/material';

import { Colors } from 'design/theme';

import { AudioProfilesAdmins } from 'types/admin.requests';

import AbstractAudioPlayer from './AbstractAudioPlayer';

interface AudioProfileCardProps {
  audio: AudioProfilesAdmins;
  isLargeScreen: boolean;
  isPlaying: boolean;
  isReview?: boolean;
  onTogglePlay: () => void;
  onOpenDialog: () => void;
  handleOpenModal: (audio: AudioProfilesAdmins) => void;
  handleDelete?: () => void;
}

export const AudioProfileCard: React.FC<AudioProfileCardProps> = ({
  audio,
  isLargeScreen,
  isPlaying,
  isReview,
  onTogglePlay,
  onOpenDialog,
  handleOpenModal,
  handleDelete
}) => (
  <Button onClick={() => handleOpenModal(audio)} sx={buttonStyle}>
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'left',
        width: '100%',
        padding: '1rem'
      }}>
      <Typography fontFamily='"Nunito"' fontSize="1rem" fontWeight={600}>
        Title: <span style={{ fontWeight: 700 }}>{audio.profileName}</span>
      </Typography>
      <Typography
        fontFamily='"Nunito"'
        fontSize="1rem"
        fontWeight={400}
        sx={{ marginTop: '0.15rem', paddingBottom: '0.5rem' }}>
        Description:{' '}
        <span style={{ fontWeight: 700 }}>{audio.profileDescription}</span>
      </Typography>

      <Typography
        fontFamily='"Nunito"'
        fontSize="1rem"
        fontWeight={800}
        sx={{
          marginTop: '0.15rem',
          borderTop: '0.5px solid',
          paddingTop: '0.5rem'
        }}>
        Audio Profile Parameters:
      </Typography>
      <Box
        sx={{
          marginBottom: '1rem',
          flexDirection: 'column'
        }}>
        <Typography
          fontFamily='"Nunito"'
          fontSize="1rem"
          fontWeight={400}
          sx={{ marginTop: '0.15rem' }}>
          Voice: <span style={{ fontWeight: 700 }}>{audio.voice}</span>
        </Typography>
        <Typography
          fontFamily='"Nunito"'
          fontSize="1rem"
          fontWeight={400}
          sx={{ marginTop: '0.15rem' }}>
          Voice Speed:{' '}
          <span style={{ fontWeight: 700 }}>{audio.voiceSpeed}</span>
        </Typography>
        <Typography
          fontFamily='"Nunito"'
          fontSize="1rem"
          fontWeight={400}
          sx={{ marginTop: '0.15rem' }}>
          Background Music:{' '}
          <span style={{ fontWeight: 700 }}>{audio.background}</span>
        </Typography>
        <Typography
          fontFamily='"Nunito"'
          fontSize="1rem"
          fontWeight={400}
          sx={{ marginTop: '0.15rem' }}>
          Background Volume:{' '}
          <span style={{ fontWeight: 700 }}>{audio.backgroundVolume}</span>
        </Typography>
        <Typography
          fontFamily='"Nunito"'
          fontSize="1rem"
          fontWeight={400}
          sx={{ marginTop: '0.15rem' }}>
          Published:{' '}
          <span style={{ fontWeight: 700 }}>
            {audio.Published ? 'True' : 'False'}
          </span>
        </Typography>
        <Typography
          fontFamily='"Nunito"'
          fontSize="1rem"
          fontWeight={400}
          sx={{ marginTop: '0.15rem' }}>
          In Review:{' '}
          <span style={{ fontWeight: 700 }}>
            {audio.review ? 'True' : 'False'}
          </span>
        </Typography>
        <Typography
          fontFamily='"Nunito"'
          fontSize="1rem"
          fontWeight={400}
          sx={{
            marginTop: '0.15rem',
            '& span': {
              display: 'block',
              fontWeight: 700,
              ':before': {
                content: '"- "',
                fontWeight: 'normal'
              }
            }
          }}>
          Publish to:
          {[audio.library && 'Library', audio.onboarding && 'Onboarding']
            .filter(Boolean)
            .map((item, index) => (
              <span key={index}>{item}</span>
            ))}
        </Typography>
      </Box>
      {isLargeScreen ? (
        <Box
          sx={{
            padding: '1rem',
            border: `1px solid ${Colors.greenBlue}`,
            borderRadius: '1rem'
          }}>
          <AbstractAudioPlayer
            url={audio.audioURL}
            isPlaying={isPlaying}
            onTogglePlay={onTogglePlay}
          />
        </Box>
      ) : (
        <AbstractAudioPlayer
          url={audio.audioURL}
          isPlaying={isPlaying}
          onTogglePlay={onTogglePlay}
        />
      )}
      <Box sx={{ display: 'flex', margin: '1rem 0' }}>
        <Button
          variant="contained"
          onClick={(e) => {
            e.stopPropagation();
            onOpenDialog();
          }}
          disabled={isReview ? audio.Published : audio.review}>
          {isReview ? 'Accept' : 'Publish'}
        </Button>
      </Box>
    </Box>
  </Button>
);

const buttonStyle = {
  width: '100%',
  height: '100%',
  background: Colors.white,
  borderRadius: '0.25rem',
  display: 'flex',
  flexDirection: 'row',
  color: Colors.green.main,
  border: `1px solid ${Colors.lightGrey}`,
  borderBottom: `0.5rem solid ${Colors.green.light}`,
  '&:hover': {
    background: Colors.green.light,
    color: Colors.greenBlue
  },
  '&:active': {
    boxShadow: 'inset 0px 2px 0px 0px rgba(255, 255, 255, 0)',
    background: Colors.green.main,
    color: Colors.green.dark,
    borderBottom: `0.5rem solid ${Colors.greenBlue}`
  }
};
