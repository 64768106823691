enum Roles {
  UNKNOW = 'unknow',
  ADMIN = 'ADMIN',
  SUPERADMIN = 'SUPERADMIN'
}

export enum AdminRoles {
  ADMIN = 'ADMIN',
  SUPERADMIN = 'SUPERADMIN'
}

export default Roles;
