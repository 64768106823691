import { useAppDispatch } from 'hooks/useAppDispatch';
import { useEffect, useState } from 'react';

import {
  Box,
  Button,
  FormControlLabel,
  Modal,
  Switch,
  TextField,
  Typography
} from '@mui/material';

import { Colors } from 'design/theme';

import { getAudioProfiles, updateAudioDetails } from 'redux/admin/admin.action';

import { AudioProfilesAdmins, UpdateAudioDto } from 'types/admin.requests';

interface AudioProps {
  open: boolean;
  handleClose: () => void;
  currentAudioData: AudioProfilesAdmins;
  handleOpenDeleteModal?: () => void;
}

interface UpdatedAudioInfo {
  profileName: string;
  profileDescription: string;
  onboarding: boolean;
  library: boolean;
}

const AudioModal = ({
  open,
  handleClose,
  currentAudioData,
  handleOpenDeleteModal
}: AudioProps) => {
  const dispatch = useAppDispatch();
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [audioInfo, setAudioInfo] = useState<UpdatedAudioInfo>({
    profileName: currentAudioData?.profileName || '',
    profileDescription: currentAudioData?.profileDescription || '',
    onboarding: currentAudioData?.onboarding || false,
    library: currentAudioData?.library || false
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = event.target;
    if (type === 'checkbox') {
      setAudioInfo({ ...audioInfo, [name]: checked });
    } else {
      setAudioInfo({ ...audioInfo, [name]: value });
    }
  };

  const hasChanges =
    JSON.stringify({
      profileName: currentAudioData?.profileName,
      profileDescription: currentAudioData?.profileDescription
    }) !== JSON.stringify(audioInfo);

  const saveChanges = async () => {
    setErrorMessage('');
    if (hasChanges) {
      setIsSubmitting(true);
      const updateData: UpdateAudioDto = {
        id: currentAudioData.id,
        profileName: audioInfo.profileName,
        profileDescription: audioInfo.profileDescription,
        onboarding: audioInfo.onboarding,
        library: audioInfo.library
      };

      const result = await dispatch(updateAudioDetails(updateData));
      if (updateAudioDetails.fulfilled.match(result)) {
        await dispatch(getAudioProfiles());
        setIsSubmitting(false);
        handleClose();
      }
    }
  };

  useEffect(() => {
    setAudioInfo({
      profileName: currentAudioData?.profileName || '',
      profileDescription: currentAudioData?.profileDescription || '',
      onboarding: currentAudioData?.onboarding || false,
      library: currentAudioData?.library || false
    });
  }, [currentAudioData, dispatch]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="affirmations-modal-title"
      aria-describedby="affirmations-modal-description">
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '50%',
          maxHeight: '75%',
          bgcolor: 'background.paper',
          border: `2px solid ${Colors.secondary.main}`,
          borderRadius: '2rem',
          boxShadow: 24,
          p: 4
        }}>
        <Typography
          variant="h1"
          fontSize={'1rem'}
          fontFamily={'"Nunito"'}
          fontWeight={600}
          lineHeight={'1.75rem'}
          mb={'1.15rem'}
          sx={{
            color: Colors.secondary.dark
          }}>
          Review your Audio Details
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: '10rem',
            justifyContent: 'space-around',
            gap: '1.15rem'
          }}>
          <TextField
            color="secondary"
            fullWidth
            value={audioInfo.profileName}
            onChange={handleChange}
            name="profileName"
            id="profileName"
            label="Title"
            placeholder="Title *"
            autoComplete="profileName"
            sx={{
              fontFamily: '"Nunito"',
              fontWeight: '500',
              color: Colors.brown.main
            }}
          />
          <TextField
            color="secondary"
            fullWidth
            value={audioInfo.profileDescription}
            onChange={handleChange}
            name="profileDescription"
            id="profileDescription"
            label="Description"
            placeholder="Description *"
            autoComplete="profileDescription"
            sx={{
              fontFamily: '"Nunito"',
              fontWeight: '500',
              color: Colors.brown.main
            }}
          />
          <FormControlLabel
            control={
              <Switch
                checked={audioInfo.onboarding}
                onChange={handleChange}
                name="onboarding"
                color="primary"
              />
            }
            label="Onboarding"
          />
          <FormControlLabel
            control={
              <Switch
                checked={audioInfo.library}
                onChange={handleChange}
                name="library"
                color="primary"
              />
            }
            label="Library"
          />
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between'
            }}>
            <Box>
              <Button onClick={handleOpenDeleteModal} disabled={isSubmitting}>
                Delete
              </Button>
            </Box>
            <Box sx={{ display: 'flex', gap: '1rem' }}>
              <Button onClick={handleClose} disabled={isSubmitting}>
                Cancel
              </Button>
              <Button
                disabled={!hasChanges || isSubmitting}
                onClick={saveChanges}>
                Save
              </Button>
            </Box>
          </Box>
          {errorMessage && (
            <Typography color="error" sx={{ mt: 2 }}>
              {errorMessage}
            </Typography>
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export default AudioModal;
