import { Form, Formik } from 'formik';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import useIsLargeScreen from 'hooks/useIsLargeScreen';
import React, { useState } from 'react';

import { Close, Visibility } from '@mui/icons-material';
import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography
} from '@mui/material';

import { Colors } from 'design/theme';

import { createAdmin } from 'redux/admin/admin.action';
import { adminSelector, adminUiSelector } from 'redux/admin/admin.selector';

import createAdminRequest from 'types/createAdmin.type';
import { AdminRoles } from 'types/roles.enum';
import { validationSchemaCreateAdmin } from 'types/validationSchemas';

import { CircularLogoLoader } from './CircularLogoLoader';

interface CreateAdminModalProps {
  openModal: boolean;
  onCloseModal: () => void;
}

export const CreateAdminModal: React.FC<CreateAdminModalProps> = ({
  openModal,
  onCloseModal
}) => {
  const dispatch = useAppDispatch();
  const isLargeScreen = useIsLargeScreen();
  const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false);

  const handleSubmit = async (values: createAdminRequest) => {
    const result = await dispatch(createAdmin(values));
    setShowSuccessMessage(true);
    if (createAdmin.fulfilled.match(result)) {
      setShowSuccessMessage(false);
      onCloseModal();
    }
  };
  const { loading, requestError } = useAppSelector(adminUiSelector);
  const adminInfo = useAppSelector(adminSelector).ui.adminInfo;
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const availableRoles =
    adminInfo?.subscriptionType !== AdminRoles.SUPERADMIN
      ? { ADMIN: AdminRoles.ADMIN }
      : AdminRoles;

  return (
    <Modal
      open={openModal}
      onClose={onCloseModal}
      aria-labelledby="user-profile-modal-title"
      aria-describedby="user-profile-modal-description">
      <Box sx={isLargeScreen ? style : styleMobile}>
        <Box>
          <Formik
            initialValues={{
              name: '',
              email: '',
              password: '',
              role: AdminRoles.ADMIN
            }}
            onSubmit={(values) => handleSubmit(values)}
            validateOnChange={false}
            validateOnBlur={false}
            validationSchema={validationSchemaCreateAdmin}>
            {(formik) => (
              <Form>
                <Box
                  sx={{
                    margin: '2rem',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between'
                  }}>
                  <Box>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between">
                      <Typography
                        fontSize="2rem"
                        fontWeight={500}
                        color={Colors.secondary.dark}>
                        Create Admin
                      </Typography>
                      <IconButton
                        onClick={() => {
                          onCloseModal();
                        }}
                        size="small">
                        <Close />
                      </IconButton>
                    </Box>
                    <Grid container spacing={2} mt="1rem">
                      <Grid item xs={6} mb="1rem">
                        <TextField
                          color="secondary"
                          required
                          fullWidth
                          id="name"
                          name="name"
                          placeholder="Admin name*"
                          autoComplete="name"
                          value={formik.values.name}
                          onChange={(e) =>
                            formik.setFieldValue('name', e.target.value)
                          }
                          error={!!formik.errors.name}
                          sx={{
                            fontFamily: '"Nunito"',
                            fontSize: '1rem',
                            lineHeight: '1.5rem',
                            fontWeight: '500',
                            color: Colors.brown.main
                          }}
                        />
                      </Grid>
                      <Grid item xs={6} mb="1rem">
                        <FormControl fullWidth>
                          <InputLabel id="role-selector-label">Role</InputLabel>
                          <Select
                            labelId="role-selector-label"
                            id="role"
                            name="role"
                            label="Role"
                            value={formik.values.role}
                            onChange={(e) =>
                              formik.setFieldValue('role', e.target.value)
                            }
                            error={!!formik.errors.role}
                            displayEmpty
                            inputProps={{ 'aria-label': 'Without label' }}>
                            {Object.entries(availableRoles).map(
                              ([key, value]) => (
                                <MenuItem key={key} value={value}>
                                  {value}
                                </MenuItem>
                              )
                            )}
                          </Select>
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} mb="1rem">
                        <TextField
                          color="secondary"
                          required
                          fullWidth
                          id="email"
                          name="email"
                          placeholder="Email *"
                          autoComplete="email"
                          type="email"
                          value={formik.values.email}
                          onChange={(e) =>
                            formik.setFieldValue('email', e.target.value)
                          }
                          error={!!formik.errors.email}
                          sx={{
                            fontFamily: '"Nunito"',
                            fontSize: '1rem',
                            lineHeight: '1.5rem',
                            fontWeight: '500',
                            color: Colors.brown.main
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} mb="1rem">
                        <TextField
                          color="secondary"
                          required
                          fullWidth
                          placeholder="Password *"
                          name="password"
                          type={!showPassword ? 'password' : ''}
                          id="password"
                          autoComplete="current-password"
                          value={formik.values.password}
                          onChange={(e) =>
                            formik.setFieldValue('password', e.target.value)
                          }
                          error={!!formik.errors.password}
                          InputProps={{
                            endAdornment: (
                              <IconButton
                                onMouseEnter={() => setShowPassword(true)}
                                onMouseLeave={() => setShowPassword(false)}>
                                <Visibility />
                              </IconButton>
                            )
                          }}
                          sx={{
                            fontFamily: '"Nunito"',
                            fontSize: '1rem',
                            lineHeight: '1.5rem',
                            fontWeight: '500',
                            color: Colors.brown.main
                          }}
                        />
                      </Grid>
                      <Grid item minHeight={'0.625rem'} mt={'0.125rem'}>
                        {!!formik.errors.email ||
                          (!!formik.errors.password && (
                            <Typography
                              color="error"
                              variant="body1"
                              fontFamily='"Nunito"'
                              fontWeight={500}
                              fontSize={'0.75rem'}
                              lineHeight={'1rem'}>
                              Email or password incorrect
                            </Typography>
                          ))}
                        {requestError && (
                          <Typography
                            color="error"
                            variant="body1"
                            fontWeight={500}
                            fontSize={'0.875rem'}
                            lineHeight={'0.625rem'}>
                            {requestError}
                          </Typography>
                        )}
                      </Grid>
                    </Grid>
                  </Box>

                  <Box>
                    {loading ? (
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center'
                        }}>
                        <CircularLogoLoader />
                      </Box>
                    ) : (
                      <>
                        <Button
                          color="secondary"
                          type="submit"
                          fullWidth
                          variant="contained"
                          sx={{
                            mt: 2,
                            mb: 1,
                            fontSize: '1rem',
                            minHeight: '3.25rem',
                            textTransform: 'none',
                            fontFamily: '"Nunito"',
                            lineHeight: '1.25rem',
                            letterSpacing: '0.005rem',
                            fontWeight: '500',
                            color: Colors.white
                          }}>
                          {'Create'}
                        </Button>
                      </>
                    )}
                    {!loading && showSuccessMessage && (
                      <Typography color="primary">
                        User created successfully!
                      </Typography>
                    )}
                  </Box>
                </Box>
              </Form>
            )}
          </Formik>
        </Box>
      </Box>
    </Modal>
  );
};
export const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  background: Colors.white,
  display: 'flex',
  flexDirection: 'column',
  boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px'
};

export const styleMobile = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  maxHeight: '50rem',
  background: Colors.white,
  boxShadow: 24,
  p: 4,
  borderRadius: '0.5rem',
  padding: '0',
  alignItems: 'center',
  justifyContent: 'center',
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '100vw'
};
