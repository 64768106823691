import { useAppDispatch } from 'hooks/useAppDispatch';
import useIsLargeScreen from 'hooks/useIsLargeScreen';
import React, { useState } from 'react';

import { Box, Button, Container, Grid } from '@mui/material';

import { Colors } from 'design/theme';

import { getPromotionCodes, getWaitingList } from 'redux/admin/admin.action';

import { PromotionCode } from './PromotionCodeForm';
import UsersPanel from './UsersPanel';
import WaitingList from './WaitingList';

export const UsersDashboard: React.FC = () => {
  const isLargeScreen = useIsLargeScreen();

  const getTabOptions = (isLargeScreen: boolean) => ({
    CURRENT_USERS: 'Users',
    WAITING_USERS: isLargeScreen ? 'Waiting List' : 'Waiting',
    PROMOTION_CODE: isLargeScreen ? 'Promotion Code' : 'Promotions'
  });

  const TabOptions = getTabOptions(isLargeScreen);

  // Create a type for TabOptions values
  type TabOptionsType = (typeof TabOptions)[keyof typeof TabOptions];

  const dispatch = useAppDispatch();
  const [activeTab, setActiveTab] = useState<TabOptionsType>(
    TabOptions.CURRENT_USERS
  );

  const handleTabClick = (tab: TabOptionsType) => {
    setActiveTab(tab);
    if (tab === TabOptions.WAITING_USERS) {
      dispatch(getWaitingList());
    }
    if (tab === TabOptions.PROMOTION_CODE) {
      dispatch(getPromotionCodes());
    }
  };

  return (
    <Container
      sx={{
        margin: 'auto',
        justifyContent: 'center',
        pb: '2rem'
      }}>
      <Grid
        container
        spacing={isLargeScreen ? 1 : 2}
        sx={{ borderBottom: `1px solid ${Colors.grey}` }}>
        <Grid item>
          <Button
            sx={{
              fontFamily: '"Nunito"',
              fontSize: '1.15rem',
              borderBottom:
                activeTab === TabOptions.CURRENT_USERS
                  ? `2px solid ${Colors.black}`
                  : '2px solid transparent',
              borderRadius: '0',
              color:
                activeTab === TabOptions.CURRENT_USERS
                  ? Colors.black
                  : Colors.neutral,
              fontWeight: '500'
            }}
            onClick={() => handleTabClick(TabOptions.CURRENT_USERS)}>
            {TabOptions.CURRENT_USERS}
          </Button>
        </Grid>
        <Grid item>
          <Button
            sx={{
              fontFamily: '"Nunito"',
              fontSize: '1.15rem',
              borderBottom:
                activeTab === TabOptions.WAITING_USERS
                  ? `2px solid ${Colors.black}`
                  : '2px solid transparent',
              borderRadius: '0',
              color:
                activeTab === TabOptions.WAITING_USERS
                  ? Colors.black
                  : Colors.neutral,
              fontWeight: '500'
            }}
            onClick={() => handleTabClick(TabOptions.WAITING_USERS)}>
            {TabOptions.WAITING_USERS}
          </Button>
        </Grid>
        <Grid item>
          <Button
            sx={{
              fontFamily: '"Nunito"',
              fontSize: '1.15rem',
              borderBottom:
                activeTab === TabOptions.PROMOTION_CODE
                  ? `2px solid ${Colors.black}`
                  : '2px solid transparent',
              borderRadius: '0',
              color:
                activeTab === TabOptions.PROMOTION_CODE
                  ? Colors.black
                  : Colors.neutral,
              fontWeight: '500'
            }}
            onClick={() => handleTabClick(TabOptions.PROMOTION_CODE)}>
            {TabOptions.PROMOTION_CODE}
          </Button>
        </Grid>
      </Grid>
      <Box
        sx={{
          marginTop: '1rem',
          padding: 0
        }}>
        {activeTab === TabOptions.CURRENT_USERS && (
          <>
            <UsersPanel />
          </>
        )}
        {activeTab === TabOptions.WAITING_USERS && (
          <>
            <WaitingList />
          </>
        )}
        {activeTab === TabOptions.PROMOTION_CODE && (
          <>
            <PromotionCode />
          </>
        )}
      </Box>
    </Container>
  );
};

export default UsersDashboard;
