import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import useIsLargeScreen from 'hooks/useIsLargeScreen';
import React, { useState } from 'react';

import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import {
  Box,
  IconButton,
  Skeleton,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';

import {
  StyledTableCell,
  StyledTableContainer,
  StyledTableHeadCell
} from 'components/Users/UsersPanel';

import { Colors } from 'design/theme';

import { adminSelector } from 'redux/admin/admin.selector';
import {
  getAllAffiliationsProcess,
  updateStatusReviewProcess
} from 'redux/affiliations/affiliations.action';
import { affiliationSelector } from 'redux/affiliations/affiliations.selector';
import { Affiliation } from 'redux/affiliations/types/affiliations.interface';

import { AdminRoles } from 'types/roles.enum';

import { textColorSelector } from 'utils/textColorSelector';

import ConfirmDialog from './ConfirmDialog';
import RequesterDetailsModal from './RequesterDetailsModal';

export const AffiliatesPanel: React.FC = () => {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState<boolean>(false);
  const [selectedRequester, setSelectedRequester] =
    useState<Affiliation | null>(null);

  const [confirmOpen, setConfirmOpen] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<string | null>(null);
  const affiliationRequests =
    useAppSelector(affiliationSelector).affiliationRequests;
  const adminInfo = useAppSelector(adminSelector).ui.adminInfo;
  const handleCloseModal = () => setOpen(false);
  const isLargeScreen = useIsLargeScreen();

  const handleRowClick = (requester: Affiliation) => {
    setSelectedRequester(requester);
    setOpen(true);
  };

  const handleConfirmClose = () => {
    setConfirmOpen(false);
  };

  const handleConfirmOpen = (id: string) => {
    setSelectedId(id);
    setConfirmOpen(true);
  };

  const handleConfirm = async () => {
    if (selectedId) {
      await dispatch(
        updateStatusReviewProcess({
          id: selectedId,
          statusReview: true,
          statusAffiliate: true
        })
      );
      setConfirmOpen(false);
      dispatch(getAllAffiliationsProcess());
    }
  };

  const handleReject = async () => {
    if (selectedId) {
      await dispatch(
        updateStatusReviewProcess({
          id: selectedId,
          statusReview: true,
          statusAffiliate: false
        })
      );
      setConfirmOpen(false);
      dispatch(getAllAffiliationsProcess());
    }
  };

  if (!affiliationRequests) {
    return (
      <StyledTableContainer>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <StyledTableHeadCell>Name</StyledTableHeadCell>
              <StyledTableHeadCell align="center">Email</StyledTableHeadCell>
              <StyledTableHeadCell align="center">Country</StyledTableHeadCell>
              <StyledTableHeadCell align="center">Socials</StyledTableHeadCell>
              <StyledTableHeadCell align="center">
                Status Review
              </StyledTableHeadCell>
              <StyledTableHeadCell align="center">
                Status Affiliation
              </StyledTableHeadCell>
              {adminInfo?.subscriptionType === AdminRoles.SUPERADMIN && (
                <StyledTableHeadCell align="center">Accept</StyledTableHeadCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {[...Array(4)].map((_, index) => (
              <TableRow key={index}>
                <StyledTableCell component="th" scope="row">
                  <Skeleton variant="text" width="100%" />
                </StyledTableCell>
                <StyledTableCell align="right">
                  <Skeleton variant="text" width="100%" />
                </StyledTableCell>
                <StyledTableCell align="right">
                  <Skeleton variant="text" width="100%" />
                </StyledTableCell>
                <StyledTableCell align="right">
                  <Skeleton variant="text" width="100%" />
                </StyledTableCell>
                <StyledTableCell align="right">
                  <Skeleton variant="text" width="100%" />
                </StyledTableCell>
                {adminInfo?.subscriptionType === AdminRoles.SUPERADMIN && (
                  <StyledTableCell align="right">
                    <Skeleton variant="text" width="100%" />
                  </StyledTableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </StyledTableContainer>
    );
  }

  return (
    <Box
      sx={{
        paddingLeft: isLargeScreen ? '1rem' : '0',
        margin: 0,
        width: '100%'
      }}>
      <Box
        sx={{
          marginTop: '0.5rem',
          marginBottom: '1rem',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between'
        }}>
        <Typography
          fontWeight={500}
          color={Colors.bgGreen}
          fontFamily={'"Nunito"'}
          fontSize={'1.25em'}>
          Affiliates requests
        </Typography>
      </Box>
      <StyledTableContainer>
        <Table
          size={isLargeScreen ? 'medium' : 'small'}
          aria-label="simple table">
          <TableHead>
            <TableRow>
              {isLargeScreen && <StyledTableHeadCell>Name</StyledTableHeadCell>}
              <StyledTableHeadCell align="center">Email</StyledTableHeadCell>
              {isLargeScreen && (
                <StyledTableHeadCell align="center">
                  Country
                </StyledTableHeadCell>
              )}
              <StyledTableHeadCell align="center">Socials</StyledTableHeadCell>
              <StyledTableHeadCell align="center">
                Status review
              </StyledTableHeadCell>
              <StyledTableHeadCell align="center">
                Affiliation
              </StyledTableHeadCell>
              {adminInfo?.subscriptionType === AdminRoles.SUPERADMIN && (
                <StyledTableHeadCell align="center">Accept</StyledTableHeadCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {affiliationRequests.length > 0 ? (
              affiliationRequests.map((requester) => (
                <TableRow
                  key={requester.id}
                  onClick={() => handleRowClick(requester)}
                  sx={{ cursor: 'pointer' }}>
                  {isLargeScreen && (
                    <StyledTableCell
                      sx={{
                        color: textColorSelector(requester?.statusAffiliation)
                      }}>
                      {requester?.user?.firstName +
                        ' ' +
                        requester?.user?.lastName}
                    </StyledTableCell>
                  )}
                  <StyledTableCell
                    align="center"
                    sx={{
                      overflow: 'auto',
                      color: textColorSelector(requester?.statusAffiliation)
                    }}>
                    {requester?.user?.email}
                  </StyledTableCell>
                  {isLargeScreen && (
                    <StyledTableCell
                      align="center"
                      sx={{
                        color: requester?.statusAffiliation
                          ? Colors.secondary.main
                          : undefined
                      }}>
                      {requester?.user?.country}
                    </StyledTableCell>
                  )}

                  <StyledTableCell
                    align="center"
                    sx={{
                      color: textColorSelector(requester?.statusAffiliation)
                    }}>
                    {requester?.socials
                      ?.map((social) => social.social)
                      .join(', ')}
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    sx={{
                      color: textColorSelector(requester?.statusAffiliation)
                    }}>
                    {requester?.statusReview ? 'Reviewed' : 'In Review'}
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    sx={{
                      color: textColorSelector(requester?.statusAffiliation)
                    }}>
                    {requester?.statusAffiliation &&
                      requester?.statusReview &&
                      'Accepted'}
                    {!requester?.statusAffiliation &&
                      !requester?.statusReview &&
                      'Pending'}
                    {!requester?.statusAffiliation &&
                      requester?.statusReview &&
                      'Rejected'}
                  </StyledTableCell>
                  {adminInfo?.subscriptionType === AdminRoles.SUPERADMIN && (
                    <StyledTableCell
                      align="center"
                      sx={{
                        color: textColorSelector(requester?.statusAffiliation)
                      }}>
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation();
                          handleConfirmOpen(requester.id);
                        }}>
                        <ThumbUpAltIcon
                          color={
                            requester?.statusAffiliation
                              ? 'secondary'
                              : undefined
                          }
                        />
                      </IconButton>
                    </StyledTableCell>
                  )}
                </TableRow>
              ))
            ) : (
              <TableRow sx={{ cursor: 'pointer' }}>
                {isLargeScreen && <StyledTableCell>{'- - -'}</StyledTableCell>}
                <StyledTableCell
                  align="center"
                  sx={{
                    overflow: 'auto'
                  }}>
                  {'- - -'}
                </StyledTableCell>
                {isLargeScreen && (
                  <StyledTableCell align="center">{'- - -'}</StyledTableCell>
                )}

                <StyledTableCell align="center">{'- - -'}</StyledTableCell>
                <StyledTableCell align="center">{'- - -'}</StyledTableCell>
                <StyledTableCell align="center">{'- - -'}</StyledTableCell>
                {adminInfo?.subscriptionType === AdminRoles.SUPERADMIN && (
                  <StyledTableCell align="center">{'- - -'}</StyledTableCell>
                )}
              </TableRow>
            )}
          </TableBody>
        </Table>
      </StyledTableContainer>
      <RequesterDetailsModal
        open={open}
        onClose={handleCloseModal}
        requester={selectedRequester}
      />
      <ConfirmDialog
        open={confirmOpen}
        onClose={handleConfirmClose}
        onConfirm={handleConfirm}
        onReject={handleReject}
        title="Confirm Action"
        content={'Are you want to accept or reject this affiliation request?'}
      />
    </Box>
  );
};

export default AffiliatesPanel;
