import React from 'react';

import { Box, Button, Grid, Typography } from '@mui/material';

import { Affiliation } from 'redux/affiliations/types/affiliations.interface';

interface AgreementsProps {
  requester: Affiliation | null;
}

export const AgreementsDetails: React.FC<AgreementsProps> = ({ requester }) => {
  return (
    <Grid container spacing={2} mt={1}>
      <Grid item xs={8}>
        <Typography>Credential One</Typography>
      </Grid>
      <Grid item xs={4}>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            variant="contained"
            disabled={!requester?.credentials?.firstId}
            href={requester?.credentials?.firstId}>
            {!requester?.credentials?.firstId ? 'Not Avaible' : 'View'}
          </Button>
        </Box>
      </Grid>
      <Grid item xs={8}>
        <Typography>Credential Two</Typography>
      </Grid>
      <Grid item xs={4}>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            variant="contained"
            disabled={!requester?.credentials?.secondId}
            href={requester?.credentials?.secondId}>
            {!requester?.credentials?.secondId ? 'Not Avaible' : 'View'}
          </Button>
        </Box>
      </Grid>
      <Grid item xs={8}>
        <Typography>Non Disclosure Agreement</Typography>
      </Grid>
      <Grid item xs={4}>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            variant="contained"
            disabled={!requester?.credentials?.NDA}
            href={requester?.credentials?.NDA}>
            {!requester?.credentials?.NDA ? 'Not Avaible' : 'View'}
          </Button>
        </Box>
      </Grid>
      <Grid item xs={8}>
        <Typography>Affiliate Agreement</Typography>
      </Grid>
      <Grid item xs={4}>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            variant="contained"
            disabled={!requester?.credentials?.AA}
            href={requester?.credentials?.AA}>
            {!requester?.credentials?.AA ? 'Not Avaible' : 'View'}
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};
