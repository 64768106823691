import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import {
  confirmCredentialsProcess,
  getAllAffiliationsProcess,
  updateStatusReviewProcess
} from './affiliations.action';
import { Affiliation, IAffiliationState } from './types/affiliations.interface';

const initialState: IAffiliationState = {
  loading: false,
  error: ''
};

const affiliationsSlice = createSlice({
  name: 'affiliations',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllAffiliationsProcess.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        getAllAffiliationsProcess.fulfilled,
        (state, action: PayloadAction<Affiliation[]>) => {
          state.loading = false;
          state.affiliationRequests = action.payload;
        }
      )
      .addCase(getAllAffiliationsProcess.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to fetch affiliations';
      })
      .addCase(updateStatusReviewProcess.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        updateStatusReviewProcess.fulfilled,
        (state, action: PayloadAction<Affiliation>) => {
          state.loading = false;
          if (state.affiliationRequests) {
            const index = state.affiliationRequests.findIndex(
              (aff) => aff.id === action.payload.id
            );
            if (index !== -1) {
              state.affiliationRequests[index] = action.payload;
            }
          }
        }
      )
      .addCase(updateStatusReviewProcess.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to update status review';
      })
      .addCase(confirmCredentialsProcess.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        confirmCredentialsProcess.fulfilled,
        (state, action: PayloadAction<boolean>) => {
          state.loading = false;
        }
      )
      .addCase(confirmCredentialsProcess.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to confirm credentials';
      });
  }
});

export default affiliationsSlice.reducer;
