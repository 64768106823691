import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import { useEffect, useState } from 'react';

import CheckIcon from '@mui/icons-material/Check';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography
} from '@mui/material';

import { CircularLogoLoader } from 'components/CircularLogoLoader';

import { Colors } from 'design/theme';

import { createSelectedAffirmations } from 'redux/admin/admin.action';
import { adminSelector, adminUiSelector } from 'redux/admin/admin.selector';
import {
  resetAffirmations,
  resetSuccessMessage
} from 'redux/admin/admin.slice';

import { Affirmation } from 'types/admin.requests';

import ListItemSelectLoader from '../ListItemLoader';
import ListItemSelect from '../ListItemSelect';

const SelectAffirmations = () => {
  const dispatch = useAppDispatch();
  const [affirmationsPerPage] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const affirmationID =
    useAppSelector(adminSelector).affirmations.affirmations?.affirmationID;
  const affirmationData =
    useAppSelector(adminSelector).affirmations.affirmations;
  const { successMessage, loading } = useAppSelector(adminUiSelector);
  const [editableAffirmations, setEditableAffirmations] = useState<
    Affirmation[]
  >([]);
  const [selectedAffirmations, setSelectedAffirmations] = useState<
    { id: string; affirmationSentence: string }[]
  >([]);

  const [editableAffirmation, setEditableAffirmation] =
    useState<Affirmation | null>(null);
  const [editText, setEditText] = useState<string>('');
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [showSuccessDialog, setShowSuccessDialog] = useState<boolean>(false);

  const toggleSelection = (affirmation: Affirmation) => {
    const exists = selectedAffirmations.some((a) => a.id === affirmation.id);
    setSelectedAffirmations((prev) =>
      exists
        ? prev.filter((a) => a.id !== affirmation.id)
        : [
            ...prev,
            {
              id: affirmation.id,
              affirmationSentence: affirmation.affirmationSentence
            }
          ]
    );
  };

  const handleSaveConfirm = () => {
    setDialogOpen(true);
  };
  const handleConfirmSave = () => {
    saveSelectedAffirmations();
    setDialogOpen(false);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleEdit = (affirmation: Affirmation) => {
    setEditableAffirmation(affirmation);
    setEditText(affirmation.affirmationSentence);
  };

  const handleCloseSuccessDialog = () => {
    setShowSuccessDialog(false);
    dispatch(resetSuccessMessage());
    dispatch(resetAffirmations());
  };

  const saveEdit = (affirmationId: string) => {
    const updatedAffirmations = editableAffirmations.map((aff) =>
      aff.id === affirmationId ? { ...aff, affirmationSentence: editText } : aff
    );
    setEditableAffirmations(updatedAffirmations);
    setEditableAffirmation(null);
  };

  const saveSelectedAffirmations = () => {
    if (affirmationID && selectedAffirmations.length > 0) {
      const data = {
        affirmationID: affirmationID,
        affirmations: selectedAffirmations
      };

      dispatch(createSelectedAffirmations(data));
    }
  };

  const indexOfLastAffirmation = currentPage * affirmationsPerPage;
  const indexOfFirstAffirmation = indexOfLastAffirmation - affirmationsPerPage;
  const currentAffirmations = editableAffirmations.slice(
    indexOfFirstAffirmation,
    indexOfLastAffirmation
  );
  const totalPages = Math.ceil(
    editableAffirmations.length / affirmationsPerPage
  );

  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  useEffect(() => {
    if (affirmationData && affirmationData.affirmations.length) {
      setEditableAffirmations(affirmationData.affirmations);
      setSelectedAffirmations([]);
    }
    if (successMessage) {
      setShowSuccessDialog(true);
    }
  }, [affirmationData, successMessage]);

  return (
    <>
      <Grid
        container
        justifyContent="space-between"
        mb="1rem"
        alignItems="center">
        {!loading && (
          <Grid item>
            <Typography
              fontSize={'0.875rem'}
              fontWeight={500}
              color={Colors.secondary.dark}>
              {affirmationData &&
                affirmationData?.affirmations.length > 0 &&
                'You can select up to one hundred affirmation'}
            </Typography>
          </Grid>
        )}
      </Grid>

      {loading && (
        <>
          {Array(15)
            .fill('')
            .map((_, i) => (
              <ListItemSelectLoader key={i} isLoading={loading} />
            ))}
        </>
      )}

      {!loading && currentAffirmations.length > 0 && (
        <>
          {currentAffirmations.map((affirmation, index) => (
            <div
              key={affirmation.id}
              style={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: '0.5rem'
              }}>
              {editableAffirmation?.id === affirmation.id ? (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    backgroundColor: 'transparent',
                    width: '100%',
                    cursor: 'pointer',
                    height: 'min-content',
                    mb: '0.5rem'
                  }}>
                  <TextField
                    value={editText}
                    onChange={(e) => setEditText(e.target.value)}
                    sx={{
                      flexGrow: 1,
                      color: Colors.bgGreen
                    }}
                  />
                  <IconButton
                    color="primary"
                    onClick={() => saveEdit(affirmation.id)}
                    sx={{ marginLeft: '0.5rem', color: Colors.bgGreen }}>
                    <CheckIcon />
                  </IconButton>
                </Box>
              ) : (
                <ListItemSelect
                  label={affirmation.affirmationSentence}
                  index={indexOfFirstAffirmation + index + 1}
                  isSelected={selectedAffirmations.some(
                    (a) => a.id === affirmation.id
                  )}
                  onSelect={() => toggleSelection(affirmation)}
                  onEdit={() => handleEdit(affirmation)}
                  isLoading={loading}
                />
              )}
            </div>
          ))}
        </>
      )}
      {!loading && (
        <Grid container justifyContent="flex-end" columnSpacing={2} py={4}>
          <Grid
            item
            xs={12}
            display="flex"
            columnGap="1rem"
            alignItems="center">
            {loading ? (
              <CircularLogoLoader />
            ) : (
              <>
                <Typography
                  fontSize={'0.875rem'}
                  fontWeight={500}
                  color={Colors.secondary.dark}>
                  Page {currentPage} of {totalPages}
                </Typography>
                <Button
                  onClick={() => paginate(currentPage - 1)}
                  disabled={currentPage === 1}>
                  Prev page
                </Button>
                <Button
                  onClick={() => paginate(currentPage + 1)}
                  disabled={currentPage === totalPages}>
                  Next page
                </Button>
              </>
            )}
          </Grid>
          <Grid item xs={12}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                gap: '1rem'
              }}>
              <Button
                variant="outlined"
                onClick={() => {
                  dispatch(resetAffirmations());
                }}>
                Cancel
              </Button>

              <Button
                color="secondary"
                sx={{ backgroundColor: Colors.bgGreen }}
                variant="contained"
                disabled={selectedAffirmations.length < 10}
                onClick={() => handleSaveConfirm()}>
                Save Affirmations
              </Button>
            </Box>
          </Grid>
        </Grid>
      )}
      <Dialog open={dialogOpen} onClose={handleCloseDialog}>
        <DialogTitle
          sx={{
            fontSize: '1.15rem',
            fontWeight: '500',
            color: Colors.secondary.main
          }}>
          Confirm Save
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            sx={{
              fontSize: '1rem',
              fontWeight: '500'
            }}>
            Are you ready to save this affirmations?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmSave} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={showSuccessDialog}
        onClose={handleCloseSuccessDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{'Success'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {successMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseSuccessDialog} color="primary" autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default SelectAffirmations;
