import { Outlet } from 'react-router-dom';

import { Box } from '@mui/material';

import { Colors } from 'design/theme';

const HalfFullScreenCentered = () => {
  return (
    <Box display="flex" width="100%">
      <Box
        sx={{
          minHeight: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          background: Colors.primary.light
        }}>
        <Outlet />
      </Box>
    </Box>
  );
};

export default HalfFullScreenCentered;
