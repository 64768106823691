import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import React, { useState } from 'react';

import { Box, Button, Container, Grid } from '@mui/material';

import { Colors } from 'design/theme';

import { getAudioProfiles } from 'redux/admin/admin.action';
import { adminSelector } from 'redux/admin/admin.selector';

import { AdminRoles } from 'types/roles.enum';

import { getAudioProfilesToReview } from '../../redux/admin/admin.action';
import AudioCabinet from './AudioCabinet';
import AudioHub from './AudioHub';
import { ReviewAudios } from './ReviewAudios';

enum TabOptions {
  AUDIO_CABINET = 'Audio Cabinet',
  AUDIO_LAB = 'Audio Lab',
  REVIEW = 'Review'
}

export const AudioLab: React.FC = () => {
  const dispatch = useAppDispatch();
  const [activeTab, setActiveTab] = useState<TabOptions>(TabOptions.AUDIO_LAB);
  const adminInfo = useAppSelector(adminSelector).ui.adminInfo;

  const handleTabClick = (tab: TabOptions) => {
    setActiveTab(tab);
    if (tab === TabOptions.AUDIO_CABINET) {
      dispatch(getAudioProfiles());
    }
    if (tab === TabOptions.REVIEW) {
      dispatch(getAudioProfilesToReview());
    }
  };

  return (
    <Container
      sx={{
        margin: 'auto',
        justifyContent: 'center',
        pb: '2rem'
      }}>
      <Grid
        container
        spacing={1}
        sx={{ borderBottom: `1px solid ${Colors.grey}` }}>
        <Grid item>
          <Button
            sx={{
              fontFamily: '"Nunito"',
              fontSize: '1.15rem',
              borderBottom:
                activeTab === TabOptions.AUDIO_LAB
                  ? `2px solid ${Colors.black}`
                  : '2px solid transparent',
              borderRadius: '0',
              color:
                activeTab === TabOptions.AUDIO_LAB
                  ? Colors.black
                  : Colors.neutral,
              fontWeight: '500'
            }}
            onClick={() => handleTabClick(TabOptions.AUDIO_LAB)}>
            {TabOptions.AUDIO_LAB}
          </Button>
        </Grid>
        <Grid item>
          <Button
            sx={{
              fontFamily: '"Nunito"',
              fontSize: '1.15rem',
              borderBottom:
                activeTab === TabOptions.AUDIO_CABINET
                  ? `2px solid ${Colors.black}`
                  : '2px solid transparent',
              borderRadius: '0',
              color:
                activeTab === TabOptions.AUDIO_CABINET
                  ? Colors.black
                  : Colors.neutral,
              fontWeight: '500'
            }}
            onClick={() => handleTabClick(TabOptions.AUDIO_CABINET)}>
            {TabOptions.AUDIO_CABINET}
          </Button>
        </Grid>
        {adminInfo?.subscriptionType === AdminRoles.SUPERADMIN && (
          <Grid item>
            <Button
              sx={{
                fontFamily: '"Nunito"',
                fontSize: '1.15rem',
                borderBottom:
                  activeTab === TabOptions.REVIEW
                    ? `2px solid ${Colors.black}`
                    : '2px solid transparent',
                borderRadius: '0',
                color:
                  activeTab === TabOptions.REVIEW
                    ? Colors.black
                    : Colors.neutral,
                fontWeight: '500'
              }}
              onClick={() => handleTabClick(TabOptions.REVIEW)}>
              {TabOptions.REVIEW}
            </Button>
          </Grid>
        )}
      </Grid>
      <Box sx={{ marginTop: '1.5rem' }}>
        {activeTab === TabOptions.AUDIO_CABINET && (
          <>
            <AudioCabinet />
          </>
        )}
        {activeTab === TabOptions.AUDIO_LAB && (
          <>
            <AudioHub />
          </>
        )}
        {activeTab === TabOptions.REVIEW && (
          <>
            <ReviewAudios />
          </>
        )}
      </Box>
    </Container>
  );
};

export default AudioLab;
