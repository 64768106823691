import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import useIsLargeScreen from 'hooks/useIsLargeScreen';
import React, { useState } from 'react';

import { Box, Button, Grid, Modal, Typography } from '@mui/material';

import { CircularLogoLoader } from 'components/CircularLogoLoader';

import { Colors } from 'design/theme';

import {
  confirmCredentialsProcess,
  getAllAffiliationsProcess
} from 'redux/affiliations/affiliations.action';
import { affiliationSelector } from 'redux/affiliations/affiliations.selector';
import { Affiliation } from 'redux/affiliations/types/affiliations.interface';

import { AgreementsDetails } from './AgreementsDetails';
import CredentialDetais from './CredentialDetais';

interface RequesterDetailsModalProps {
  open: boolean;
  onClose: () => void;
  requester: Affiliation | null;
}
enum TabOptions {
  CREDENTIALS = 'Information',
  AGREEMENTS = 'Contracts'
}

const RequesterDetailsModal: React.FC<RequesterDetailsModalProps> = ({
  open,
  onClose,
  requester
}) => {
  const dispatch = useAppDispatch();
  const [activeTab, setActiveTab] = useState<TabOptions>(
    TabOptions.CREDENTIALS
  );
  const { loading } = useAppSelector(affiliationSelector);
  const handleTabClick = (tab: TabOptions) => {
    setActiveTab(tab);
    dispatch(getAllAffiliationsProcess());
  };
  const isLargeScreen = useIsLargeScreen();

  const handleConfirmAffiliate = (affiliateId: string) => {
    dispatch(
      confirmCredentialsProcess({
        affiliateId: affiliateId,
        affiliateLinkCreation: true
      })
    );
    onClose();
  };

  const handleDenyAffiliate = (affiliateId: string) => {
    dispatch(
      confirmCredentialsProcess({
        affiliateId: affiliateId,
        affiliateLinkCreation: false
      })
    );
    onClose();
  };
  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: isLargeScreen ? '50%' : '80%',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          outline: 'none',
          height: '35rem',
          display: 'flex',
          flexDirection: 'column'
        }}>
        <Typography
          fontFamily={"'Nunito'"}
          fontSize={'1.25rem'}
          fontWeight={700}
          lineHeight={'2rem'}
          sx={{ color: Colors.secondary.main }}>
          Requester Details
        </Typography>
        <Grid
          container
          spacing={1}
          sx={{
            borderBottom: `1px solid ${Colors.grey}`,
            paddingTop: '0.5rem'
          }}>
          <Grid item>
            <Button
              sx={{
                fontFamily: '"Nunito"',
                fontSize: '1.15rem',
                borderBottom:
                  activeTab === TabOptions.CREDENTIALS
                    ? `2px solid ${Colors.black}`
                    : '2px solid transparent',
                borderRadius: '0',
                color:
                  activeTab === TabOptions.CREDENTIALS
                    ? Colors.black
                    : Colors.neutral,
                fontWeight: '500'
              }}
              onClick={() => handleTabClick(TabOptions.CREDENTIALS)}>
              {TabOptions.CREDENTIALS}
            </Button>
          </Grid>
          <Grid item>
            <Button
              sx={{
                fontFamily: '"Nunito"',
                fontSize: '1.15rem',
                borderBottom:
                  activeTab === TabOptions.AGREEMENTS
                    ? `2px solid ${Colors.black}`
                    : '2px solid transparent',
                borderRadius: '0',
                color:
                  activeTab === TabOptions.AGREEMENTS
                    ? Colors.black
                    : Colors.neutral,
                fontWeight: '500'
              }}
              onClick={() => handleTabClick(TabOptions.AGREEMENTS)}>
              {TabOptions.AGREEMENTS}
            </Button>
          </Grid>
        </Grid>
        <Box sx={{ flexGrow: 1, overflow: 'auto' }}>
          {activeTab === TabOptions.CREDENTIALS && (
            <>
              <CredentialDetais requester={requester} />
            </>
          )}
          {activeTab === TabOptions.AGREEMENTS && (
            <>
              <AgreementsDetails requester={requester} />
            </>
          )}
        </Box>
        <Box mt={2} display="flex" justifyContent="flex-end" gap={'1rem'}>
          {activeTab === TabOptions.CREDENTIALS && (
            <Button onClick={onClose} variant="contained" color="primary">
              Close
            </Button>
          )}
          {activeTab === TabOptions.AGREEMENTS &&
            !requester?.affiliateUrl &&
            (loading ? (
              <CircularLogoLoader />
            ) : (
              <>
                <Button
                  onClick={() => handleDenyAffiliate(requester?.id || '')}
                  variant="text"
                  disabled={!requester?.credentials?.firstId}
                  color="error">
                  Deny
                </Button>
                <Button
                  onClick={() => handleConfirmAffiliate(requester?.id || '')}
                  variant="contained"
                  disabled={!requester?.credentials?.firstId}
                  color="primary">
                  Accept
                </Button>
              </>
            ))}
          {activeTab === TabOptions.AGREEMENTS && requester?.affiliateUrl && (
            <Button variant="contained" disabled={true} color="primary">
              Done
            </Button>
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export default RequesterDetailsModal;
