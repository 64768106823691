import { ReactComponent as UserIcon } from 'assets/images/User.svg';
import { ReactComponent as Logo } from 'assets/images/iAffirm-logo.svg';
import { ReactComponent as LogoText } from 'assets/images/iAffirm-text.svg';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import useIsLargeScreen from 'hooks/useIsLargeScreen';
import { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import {
  AppBar,
  Box,
  Button,
  Container,
  Divider,
  InputBase,
  MenuItem,
  Select,
  Skeleton,
  Toolbar,
  Typography,
  styled
} from '@mui/material';

import HamburgerMenu from 'design/HamburgerMenu';
import { Colors } from 'design/theme';

import { getAdminDetails } from 'redux/admin/admin.action';
import { adminSelector, adminUiSelector } from 'redux/admin/admin.selector';
import { restoreReduxState } from 'redux/store';

import { AdminRoles } from 'types/roles.enum';
import RoutesEnum from 'types/routes.enum';

import isLogged from 'utils/isLogged';
import { removeLocalStorage } from 'utils/localstorage';

const FullScreen = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isLargeScreen = useIsLargeScreen();
  const adminInfo = useAppSelector(adminSelector).ui.adminInfo;

  const [isScrolled, setIsScrolled] = useState(false);

  const { loading } = useAppSelector(adminUiSelector);

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > 8) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  useEffect(() => {
    if (isLogged()) {
      dispatch(getAdminDetails());
    }
  }, [dispatch]);
  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          backgroundColor:
            location.pathname === '/' && !isScrolled
              ? 'transparent'
              : Colors.white,
          transition: 'background 0.3s ease-in-out',
          boxShadow: 'none'
        }}>
        <Toolbar sx={{ padding: '.50rem 1rem' }}>
          <Container>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%'
              }}>
              <Box
                display="flex"
                columnGap={isLargeScreen ? '1rem' : '.25rem'}
                alignItems="center"
                minWidth={'min-content'}>
                <Box
                  display="flex"
                  columnGap={isLargeScreen ? '1rem' : '.5rem'}
                  minWidth={'min-content'}
                  alignItems="center"
                  sx={{
                    cursor: 'pointer',
                    paddingRight: '1rem'
                  }}
                  onClick={() => {}}>
                  <Logo />
                  <LogoText />
                </Box>
              </Box>
              <Box
                display="flex"
                columnGap={isLargeScreen ? '1rem' : '.25rem'}
                alignItems="center">
                {isLogged() ? (
                  <>
                    {isLargeScreen ? (
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          padding: '0.5rem',
                          gap: isLargeScreen ? '0.5rem' : '0.125rem'
                        }}>
                        <UserIcon />
                        {loading ? (
                          <Skeleton variant="text" width="3rem" />
                        ) : (
                          <Typography
                            sx={{
                              color: Colors.primary.dark,
                              fontFamily: '"Nunito"',
                              fontSize: '1rem',
                              fontWeight: '500'
                            }}>
                            {adminInfo?.subscriptionType ===
                            AdminRoles.SUPERADMIN
                              ? 'Super Admin'
                              : 'Admin'}
                          </Typography>
                        )}
                        <Select
                          // eslint-disable-next-line react/jsx-no-undef
                          variant="standard"
                          value={''}
                          sx={{
                            background: 'transparent',
                            fontFamily: '"Nunito"'
                          }}
                          displayEmpty>
                          <MenuItem
                            sx={{
                              borderBottom: `1px solid ${Colors.neutral}`,
                              minWidth: '13rem'
                            }}>
                            <Box>
                              <Typography
                                fontFamily='"Nunito"'
                                fontSize={'1rem'}
                                letterSpacing={'0.025rem'}>
                                {adminInfo?.name || 'Profile'}
                              </Typography>
                              <Typography
                                fontFamily='"Nunito"'
                                fontSize={'0.75rem'}
                                color={Colors.neutral}
                                letterSpacing={'0.025rem'}>
                                {adminInfo?.email}
                              </Typography>
                            </Box>
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              dispatch(restoreReduxState());
                              removeLocalStorage('token');
                              navigate(RoutesEnum.LOGIN);
                              navigate(RoutesEnum.LOGIN);
                            }}>
                            Logout
                          </MenuItem>
                        </Select>
                      </Box>
                    ) : (
                      <>
                        <HamburgerMenu>
                          <>
                            <MenuItem
                              onClick={() => {
                                navigate(RoutesEnum.USER_HOME);
                              }}>
                              <Box marginLeft="auto">
                                <Typography
                                  fontFamily='"Nunito"'
                                  fontSize={'1rem'}
                                  letterSpacing={'0.025rem'}
                                  textAlign="end">
                                  {'Profile'}
                                  {'Profile'}
                                </Typography>
                                <Typography
                                  fontFamily='"Nunito"'
                                  fontSize={'0.75rem'}
                                  color={Colors.neutral}
                                  letterSpacing={'0.025rem'}
                                  textAlign="end">
                                  {adminInfo?.email}
                                </Typography>
                              </Box>
                            </MenuItem>
                            <Divider />
                            <MenuItem
                              onClick={() => {
                                dispatch(restoreReduxState());
                                removeLocalStorage('token');
                                navigate(RoutesEnum.LOGIN);
                                navigate(RoutesEnum.LOGIN);
                              }}>
                              <Typography
                                fontFamily='"Nunito"'
                                fontSize={'1rem'}
                                letterSpacing={'0.025rem'}
                                textAlign="end"
                                marginLeft="auto">
                                Logout
                              </Typography>
                            </MenuItem>
                          </>
                        </HamburgerMenu>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <>
                      <Button
                        color="secondary"
                        variant="outlined"
                        sx={{
                          padding: isLargeScreen
                            ? '0.6rem 1.5rem'
                            : '0.4rem 0.8rem',
                          borderRadius: '3.2px',
                          fontSize: isLargeScreen ? '0.8rem' : '0.6rem'
                        }}
                        onClick={() => navigate(RoutesEnum.LOGIN)}>
                        Login
                      </Button>
                    </>
                  </>
                )}
              </Box>
            </Box>
          </Container>
        </Toolbar>
      </AppBar>
      <Box
        sx={{
          pt: '4rem',
          minHeight: '100vh',
          background: 'whitesmoke',
          boxSizing: 'border-box'
        }}>
        <Outlet />
      </Box>
    </>
  );
};

export default FullScreen;
export const BootstrapInput = styled(InputBase)(({ theme }) => ({
  backgroundColor: '#FEF9F6',
  width: '1.5rem',
  height: '1.5rem',
  borderRadius: '0.25rem',
  '&.MuiSvgIcon-root': { color: theme.palette.secondary.main },
  '& .MuiInputBase-input': {
    padding: 0,
    paddingRight: '0 !important',
    fontSize: 0,
    '&:focus': {
      backgroundColor: 'transparent'
    }
  }
}));
