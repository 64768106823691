import { FieldArray, Form, Formik, FormikProps, getIn } from 'formik';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import React, { useEffect, useState } from 'react';

import {
  Box,
  Button,
  Container,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@mui/material';

import { Colors } from 'design/theme';

import { createAffirmations } from 'redux/admin/admin.action';
import { adminSelector, adminUiSelector } from 'redux/admin/admin.selector';

import { validationSchemaAffirmations } from 'types/validationSchemas';

import { CircularLogoLoader } from '../CircularLogoLoader';
import SelectAffirmations from './SelectAffirmations';

interface AiQuality {
  quality: string;
  traits: string[];
}

interface AffirmationFormValues {
  title: string;
  developmentArea: string;
  message: string;
  outcome: string;
  qualities: AiQuality[];
}

const AffirmationHub: React.FC = () => {
  const dispatch = useAppDispatch();
  const { loading, requestError } = useAppSelector(adminUiSelector);
  const affirmations = useAppSelector(adminSelector).affirmations;
  const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false);
  const [affirmationStatus, setAffirmationStatus] = useState<boolean>(false);
  const [localError, setLocalError] = useState<string>('');

  const handleSubmit = async (values: AffirmationFormValues) => {
    try {
      await dispatch(createAffirmations(values)).unwrap();
      setShowSuccessMessage(true);
      setTimeout(() => setShowSuccessMessage(false), 5000);
    } catch (error) {}
  };

  useEffect(() => {
    setAffirmationStatus(!!affirmations);
  }, [affirmations]);

  useEffect(() => {
    setLocalError(requestError || '');
  }, [requestError]);

  if (affirmationStatus && affirmations?.affirmations) {
    return (
      <Container
        sx={{
          maxWidth: '100%',
          marginTop: '3rem',
          justifyContent: 'center'
        }}>
        <SelectAffirmations />
      </Container>
    );
  }

  return (
    <Box sx={{ paddingLeft: '1rem', margin: 0, width: '100%' }}>
      <Typography
        sx={{
          fontWeight: 500,
          color: Colors.bgGreen,
          fontFamily: '"Nunito"',
          fontSize: '1.25em',
          marginTop: '0.5rem',
          marginBottom: '1rem'
        }}>
        Affirmations Lab
      </Typography>
      <Formik<AffirmationFormValues>
        initialValues={{
          title: '',
          developmentArea: '',
          message: '',
          outcome: '',
          qualities: [{ quality: '', traits: [] }]
        }}
        validationSchema={validationSchemaAffirmations}
        onSubmit={(values) => handleSubmit(values)}>
        {(formik: FormikProps<AffirmationFormValues>) => (
          <Form>
            <Grid
              container
              spacing={2}
              sx={{ minHeight: '100%', overflow: 'hidden' }}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="title"
                  name="title"
                  label="Title"
                  value={formik.values.title}
                  onChange={formik.handleChange}
                  error={Boolean(formik.touched.title && formik.errors.title)}
                  helperText={formik.touched.title && formik.errors.title}
                  inputProps={{ maxLength: 80 }}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl
                  fullWidth
                  error={Boolean(
                    formik.touched.developmentArea &&
                      formik.errors.developmentArea
                  )}>
                  <InputLabel id="developmentArea-label">
                    Development Area
                  </InputLabel>
                  <Select
                    labelId="developmentArea-label"
                    id="developmentArea"
                    name="developmentArea"
                    value={formik.values.developmentArea}
                    label="Development Area"
                    onChange={formik.handleChange}>
                    <MenuItem value="Featured">Featured</MenuItem>
                    <MenuItem value="Morning">Morning</MenuItem>
                  </Select>
                  {formik.touched.developmentArea &&
                    formik.errors.developmentArea && (
                      <FormHelperText>
                        {formik.errors.developmentArea}
                      </FormHelperText>
                    )}
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="message"
                  name="message"
                  label="Message"
                  value={formik.values.message}
                  onChange={formik.handleChange}
                  error={Boolean(
                    formik.touched.message && formik.errors.message
                  )}
                  helperText={formik.touched.message && formik.errors.message}
                  multiline
                  minRows={2}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="outcome"
                  name="outcome"
                  label="Outcome"
                  value={formik.values.outcome}
                  onChange={formik.handleChange}
                  error={Boolean(
                    formik.touched.outcome && formik.errors.outcome
                  )}
                  helperText={formik.touched.outcome && formik.errors.outcome}
                />
              </Grid>
              <Grid item xs={12}>
                <FieldArray
                  name="qualities"
                  render={(arrayHelpers) => (
                    <>
                      {formik.values.qualities.map((quality, index) => (
                        <Grid
                          container
                          key={index}
                          sx={{
                            width: '100%',
                            marginBottom: '1rem',
                            padding: '1rem',
                            border: `1px solid ${Colors.secondary.main}`,
                            borderRadius: '0.5rem'
                          }}>
                          <Grid item xs={12} sx={{ marginBottom: '1rem' }}>
                            <TextField
                              fullWidth
                              id={`qualities.${index}.quality`}
                              name={`qualities.${index}.quality`}
                              label="Quality"
                              value={quality.quality}
                              onChange={formik.handleChange}
                              error={Boolean(
                                getIn(
                                  formik.touched,
                                  `qualities.${index}.quality`
                                ) &&
                                  getIn(
                                    formik.errors,
                                    `qualities.${index}.quality`
                                  )
                              )}
                              helperText={
                                getIn(
                                  formik.touched,
                                  `qualities.${index}.quality`
                                ) &&
                                getIn(
                                  formik.errors,
                                  `qualities.${index}.quality`
                                )
                              }
                            />
                          </Grid>
                          <FieldArray
                            name={`qualities.${index}.traits`}
                            render={(traitHelpers) => (
                              <Grid item xs={12}>
                                {quality.traits.map((trait, traitIndex) => (
                                  <Grid
                                    container
                                    spacing={2}
                                    key={traitIndex}
                                    alignItems="center">
                                    <Grid item xs={12}>
                                      <TextField
                                        fullWidth
                                        id={`qualities.${index}.traits.${traitIndex}`}
                                        name={`qualities.${index}.traits.${traitIndex}`}
                                        label="Trait"
                                        value={trait}
                                        onChange={formik.handleChange}
                                        error={Boolean(
                                          getIn(
                                            formik.touched,
                                            `qualities.${index}.traits.${traitIndex}`
                                          ) &&
                                            getIn(
                                              formik.errors,
                                              `qualities.${index}.traits.${traitIndex}`
                                            )
                                        )}
                                        helperText={
                                          getIn(
                                            formik.touched,
                                            `qualities.${index}.traits.${traitIndex}`
                                          ) &&
                                          getIn(
                                            formik.errors,
                                            `qualities.${index}.traits.${traitIndex}`
                                          )
                                        }
                                      />
                                      <Button
                                        onClick={() =>
                                          traitHelpers.remove(traitIndex)
                                        }>
                                        Remove
                                      </Button>
                                    </Grid>
                                  </Grid>
                                ))}
                                {quality.traits.length < 3 && (
                                  <Button
                                    onClick={() => traitHelpers.push('')}
                                    sx={{ marginTop: '1rem' }}>
                                    Add Trait
                                  </Button>
                                )}
                              </Grid>
                            )}
                          />

                          <Button onClick={() => arrayHelpers.remove(index)}>
                            Remove Quality
                          </Button>
                        </Grid>
                      ))}
                      {formik.values.qualities.length < 3 && (
                        <Button
                          onClick={() =>
                            arrayHelpers.push({ quality: '', traits: [] })
                          }>
                          Add Quality
                        </Button>
                      )}
                    </>
                  )}
                />
              </Grid>
              {localError && (
                <Typography color="error">{localError}</Typography>
              )}
              {loading ? (
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignContent: 'center'
                  }}>
                  <CircularLogoLoader />
                </Box>
              ) : (
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignContent: 'center'
                  }}>
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{ mt: 2, mb: 1 }}>
                    Generate Affirmations
                  </Button>
                </Box>
              )}
              {!loading && showSuccessMessage && (
                <Typography color="primary">
                  Affirmations generated successfully!
                </Typography>
              )}
            </Grid>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default AffirmationHub;
