import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { SeeedMigrations } from 'types/apiResponse.interface';
import Roles from 'types/roles.enum';

import {
  adminLogin,
  audioProfilesReviewed,
  checkMorningAffirmations,
  createAdmin,
  createAffirmations,
  createAudioProfile,
  createSelectedAffirmations,
  deleteAdmin,
  deleteAdminAudio,
  generatePromotionCode,
  getAdminDetails,
  getAdminUsers,
  getAdmins,
  getAffirmationsLength,
  getAffirmationsTemplateToReview,
  getAllSeeds,
  getAudioProfiles,
  getAudioProfilesToReview,
  getCreatedAffirmations,
  getDefaultAudioProfiles,
  getPromotionCodes,
  getPublishedAudioProfiles,
  getWaitingList,
  publishAudioProfile,
  requestPassword,
  restorePassword,
  updateAudioDetails
} from './admin.action';
import {
  GeneratedAffirmations,
  IAdminState
} from './types/adminState.interface';

const initialState: IAdminState = {
  role: Roles.UNKNOW,
  ui: { loading: false },
  users: {},
  audios: {},
  affirmations: {},
  morning: false,
  promotionCode: {}
};

const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    resetAdminState: (state) => {
      return { ...initialState };
    },
    setAdminRole: (state, action: PayloadAction<Roles>) => {
      state.role = action.payload;
    },
    resetAffirmations: (state) => {
      state.affirmations.affirmations = null;
    },
    resetSuccessMessage: (state) => {
      state.ui.successMessage = '';
    },
    resetErrorMessage: (state) => {
      state.ui.loginError = '';
      state.ui.requestError = '';
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(adminLogin.fulfilled, (state) => {
        state.ui.loading = false;
      })
      .addCase(adminLogin.pending, (state) => {
        state.ui.loginError = undefined;
        state.ui.loading = true;
      })
      .addCase(adminLogin.rejected, (state, action) => {
        state.ui.loginError = action.payload?.message;
        state.ui.requestError = 'Invalid Credentials';
        state.ui.loading = false;
      })
      .addCase(createAdmin.fulfilled, (state) => {
        state.ui.loading = false;
      })
      .addCase(createAdmin.pending, (state) => {
        state.ui.requestError = undefined;
        state.ui.loading = true;
      })
      .addCase(createAdmin.rejected, (state, action) => {
        state.ui.requestError = 'Failed to create admin';
        state.ui.loading = false;
      })
      .addCase(getAdminDetails.fulfilled, (state, action) => {
        state.ui.adminInfo = action.payload.data;
        state.ui.loading = false;
      })
      .addCase(getAdminDetails.pending, (state) => {
        state.ui.requestError = undefined;
        state.ui.loading = true;
      })
      .addCase(getAdminDetails.rejected, (state, action) => {
        state.ui.requestError =
          action.error.message || 'Failed to get Admin details';
        state.ui.loading = false;
      })
      .addCase(
        createAffirmations.fulfilled,
        (state, action: PayloadAction<GeneratedAffirmations>) => {
          state.affirmations.affirmations = action.payload;
          state.ui.loading = false;
        }
      )
      .addCase(createAffirmations.pending, (state) => {
        state.ui.requestError = undefined;
        state.ui.loading = true;
      })
      .addCase(createAffirmations.rejected, (state, action) => {
        state.ui.requestError =
          action.error.message || 'Failed to create affirmations';
        state.ui.loading = false;
      })
      .addCase(deleteAdmin.fulfilled, (state) => {
        state.ui.loading = false;
      })
      .addCase(deleteAdmin.pending, (state) => {
        state.ui.requestError = undefined;
        state.ui.loading = true;
      })
      .addCase(deleteAdmin.rejected, (state, action) => {
        state.ui.requestError =
          action.error.message || 'Failed to create affirmations';
        state.ui.loading = false;
      })
      .addCase(getAdmins.fulfilled, (state, action) => {
        state.users.admins = action.payload.data;
        state.ui.loading = false;
      })
      .addCase(getAdmins.pending, (state) => {
        state.ui.loading = true;
      })
      .addCase(getAdmins.rejected, (state, action) => {
        state.ui.requestError =
          action.error.message || 'Failed to fetch admins';
        state.ui.loading = false;
      })
      .addCase(getAdminUsers.fulfilled, (state, action) => {
        state.users.users = action.payload.data;
        state.ui.loading = false;
      })
      .addCase(getAdminUsers.pending, (state) => {
        state.ui.loading = true;
      })
      .addCase(getAdminUsers.rejected, (state, action) => {
        state.ui.requestError =
          action.error.message || 'Failed to fetch admin users';
        state.ui.loading = false;
      })
      .addCase(createSelectedAffirmations.fulfilled, (state) => {
        state.ui.loading = false;
        state.ui.successMessage = 'Affirmations Product Created Sucessfuly!';
      })
      .addCase(createSelectedAffirmations.pending, (state) => {
        state.ui.requestError = undefined;
        state.ui.loading = true;
      })
      .addCase(createSelectedAffirmations.rejected, (state, action) => {
        state.ui.requestError =
          action.error.message || 'Failed to save selected affirmations';
        state.ui.loading = false;
      })
      .addCase(getCreatedAffirmations.fulfilled, (state, action) => {
        state.affirmationsCabinet = action.payload.data;
        state.ui.loading = false;
      })
      .addCase(getCreatedAffirmations.pending, (state) => {
        state.ui.loading = true;
      })
      .addCase(getCreatedAffirmations.rejected, (state, action) => {
        state.ui.requestError =
          action.error.message || 'Failed to fetch created affirmations';
        state.ui.loading = false;
      })
      .addCase(requestPassword.fulfilled, (state) => {
        state.ui.loading = false;
      })
      .addCase(requestPassword.pending, (state) => {
        state.ui.loading = true;
      })
      .addCase(requestPassword.rejected, (state, action) => {
        state.ui.requestError =
          action.error.message || 'Failed to fetch created affirmations';
        state.ui.loading = false;
      })
      .addCase(restorePassword.fulfilled, (state) => {
        state.ui.loading = false;
      })
      .addCase(restorePassword.pending, (state) => {
        state.ui.loading = true;
      })
      .addCase(restorePassword.rejected, (state, action) => {
        state.ui.requestError =
          action.error.message || 'Failed to fetch created affirmations';
        state.ui.loading = false;
      })
      .addCase(getAudioProfiles.fulfilled, (state, action) => {
        state.audios.audioProfiles = action.payload.data;
        state.ui.loading = false;
      })
      .addCase(getAudioProfiles.pending, (state) => {
        state.ui.loading = true;
      })
      .addCase(getAudioProfiles.rejected, (state, action) => {
        state.ui.requestError =
          action.error.message || 'Failed to fetch audio profiles';
        state.ui.loading = false;
      })
      .addCase(publishAudioProfile.fulfilled, (state) => {
        state.ui.loading = false;
        state.ui.successMessage = 'Audio Profile Published Successfully!';
      })
      .addCase(publishAudioProfile.pending, (state) => {
        state.ui.loading = true;
      })
      .addCase(publishAudioProfile.rejected, (state, action) => {
        state.ui.requestError =
          action.error.message || 'Failed to publish audio profile';
        state.ui.loading = false;
      })
      .addCase(audioProfilesReviewed.fulfilled, (state) => {
        state.ui.loading = false;
        state.ui.successMessage = 'Audio Profile Review Accepted!';
      })
      .addCase(audioProfilesReviewed.pending, (state) => {
        state.ui.loading = true;
      })
      .addCase(audioProfilesReviewed.rejected, (state, action) => {
        state.ui.requestError =
          action.error.message || 'Failed to review audio profile';
        state.ui.loading = false;
      })
      .addCase(getPublishedAudioProfiles.fulfilled, (state, action) => {
        state.audios.publishedAudioProfiles = action.payload.data;
        state.ui.loading = false;
      })
      .addCase(getPublishedAudioProfiles.pending, (state) => {
        state.ui.loading = true;
      })
      .addCase(getPublishedAudioProfiles.rejected, (state, action) => {
        state.ui.requestError =
          action.error.message || 'Failed to fetch published audio profiles';
        state.ui.loading = false;
      })
      .addCase(createAudioProfile.fulfilled, (state) => {
        state.ui.loading = false;
        state.ui.successMessage = 'Audio Profile Created Successfully!';
      })
      .addCase(createAudioProfile.pending, (state) => {
        state.ui.loading = true;
      })
      .addCase(createAudioProfile.rejected, (state, action) => {
        state.ui.requestError =
          action.error.message || 'Failed to create audio profile';
        state.ui.loading = false;
      })
      .addCase(getAudioProfilesToReview.fulfilled, (state, action) => {
        state.audios.audioProfilesToReview = action.payload.data;
        state.ui.loading = false;
      })
      .addCase(getAudioProfilesToReview.pending, (state) => {
        state.ui.loading = true;
      })
      .addCase(getAudioProfilesToReview.rejected, (state, action) => {
        state.ui.requestError =
          action.error.message || 'Failed to fetch audio profiles to review';
        state.ui.loading = false;
      })
      .addCase(getAffirmationsTemplateToReview.fulfilled, (state, action) => {
        state.affirmations.affirmationsReview = action.payload.data;
        state.ui.loading = false;
      })
      .addCase(getAffirmationsTemplateToReview.pending, (state) => {
        state.ui.loading = true;
      })
      .addCase(getAffirmationsTemplateToReview.rejected, (state, action) => {
        state.ui.requestError =
          action.error.message || 'Failed to fetch affirmations to review';
        state.ui.loading = false;
      })
      .addCase(updateAudioDetails.fulfilled, (state) => {
        state.ui.loading = false;
      })
      .addCase(updateAudioDetails.pending, (state) => {
        state.ui.loading = true;
      })
      .addCase(updateAudioDetails.rejected, (state, action) => {
        state.ui.requestError =
          action.error.message || 'Failed to fetch affirmations to review';
        state.ui.loading = false;
      })
      .addCase(deleteAdminAudio.fulfilled, (state) => {
        state.ui.loading = false;
      })
      .addCase(deleteAdminAudio.pending, (state) => {
        state.ui.loading = true;
      })
      .addCase(deleteAdminAudio.rejected, (state, action) => {
        state.ui.requestError =
          action.error.message || 'Failed to fetch affirmations to review';
        state.ui.loading = false;
      })
      .addCase(getDefaultAudioProfiles.fulfilled, (state, action) => {
        state.audios.userAudioProfiles = action.payload;
      })
      .addCase(getDefaultAudioProfiles.pending, (state) => {
        state.ui.loading = true;
      })
      .addCase(getDefaultAudioProfiles.rejected, (state, action) => {
        state.ui.requestError = action.error.message;
      })
      .addCase(
        checkMorningAffirmations.fulfilled,
        (state, action: PayloadAction<boolean>) => {
          state.ui.loading = false;
          state.morning = action.payload;
        }
      )
      .addCase(checkMorningAffirmations.pending, (state) => {
        state.ui.loading = true;
      })
      .addCase(checkMorningAffirmations.rejected, (state) => {
        state.ui.loading = false;
      })
      .addCase(getWaitingList.fulfilled, (state, action) => {
        state.users.waitingList = action.payload.data;
      })
      .addCase(getWaitingList.pending, (state) => {
        state.ui.loading = true;
      })
      .addCase(getWaitingList.rejected, (state) => {
        state.ui.loading = false;
      })
      .addCase(getAffirmationsLength.fulfilled, (state, action) => {
        state.affirmations.affirmationsPublished = action.payload.data.data;
      })
      .addCase(generatePromotionCode.fulfilled, (state, action) => {
        state.promotionCode.promotionCode = action.payload.data;
      })
      .addCase(generatePromotionCode.pending, (state) => {
        state.ui.loading = true;
      })
      .addCase(generatePromotionCode.rejected, (state, action) => {
        if (action.error.message === 'Request failed with status code 400')
          state.ui.requestError =
            'Promotion code with this name already exists';
      })
      .addCase(getPromotionCodes.fulfilled, (state, action) => {
        state.promotionCode.allPromotionCodes = action.payload.data;
      })
      .addCase(getPromotionCodes.pending, (state) => {
        state.ui.loading = true;
      })
      .addCase(getPromotionCodes.rejected, (state) => {
        state.ui.loading = false;
      })
      .addCase(
        getAllSeeds.fulfilled,
        (state, action: PayloadAction<SeeedMigrations[]>) => {
          state.seeds = action.payload;
        }
      )
      .addCase(getAllSeeds.pending, (state) => {
        state.ui.loading = true;
      })
      .addCase(getAllSeeds.rejected, (state) => {
        state.ui.loading = false;
      });
  }
});
export const { resetAffirmations, resetSuccessMessage, resetErrorMessage } =
  adminSlice.actions;
export default adminSlice.reducer;
