import { createAsyncThunk } from '@reduxjs/toolkit';
import _adminService from 'services/admin';

import {
  AcceptPublishAffirmationTempalteDto,
  AcceptPublishAudioDto,
  AffirmationAdminDTO,
  CreateAdminAreaDto,
  DefaultAudioAdminDTO,
  EmailRequiredDto,
  PasswordRequiredDto,
  PublishAffirmationoDto,
  PublishAudioDto,
  SelectedAffirmationAdminDTO,
  UpdateAffirmationDto,
  UpdateAudioDto
} from 'types/admin.requests';
import {
  CheckMorningAffirmationDTO,
  PromotionCodeData,
  SeedData,
  SeeedMigrations
} from 'types/apiResponse.interface';
import loginRequestType from 'types/loginRequest.type';

import { AudioLibraryDefault } from './types/adminState.interface';

export interface RejectValue {
  message?: string;
}

export const adminLogin = createAsyncThunk<
  ReturnType<typeof _adminService.login>,
  loginRequestType,
  {
    rejectValue: RejectValue;
  }
>('admin-area/login', async (data: loginRequestType, { rejectWithValue }) => {
  try {
    return await _adminService.login(data);
  } catch (error) {
    return rejectWithValue({ message: (error as Error).message });
  }
});

export const createAdmin = createAsyncThunk(
  'admin-area/create',
  async (data: CreateAdminAreaDto) => {
    return _adminService.createAdmin(data);
  }
);

export const getAdminDetails = createAsyncThunk(
  'admin-area/admin-info',
  async () => {
    return _adminService.getAdminDetails();
  }
);

export const createAffirmations = createAsyncThunk(
  'admin-area/generate-affirmations',
  async (data: AffirmationAdminDTO) => {
    return (await _adminService.createAffirmations(data)).data;
  }
);

export const deleteAdmin = createAsyncThunk(
  'admin-area/delete',
  async (adminId: string) => {
    return _adminService.deleteAdmin(adminId);
  }
);

export const getAdmins = createAsyncThunk('admin-area/admins', async () => {
  return _adminService.getAdmins();
});

export const getAdminUsers = createAsyncThunk(
  'admin-area/users-admin',
  async () => {
    return _adminService.getAdminUsers();
  }
);

export const createSelectedAffirmations = createAsyncThunk(
  'admin-area/create-selected-affirmations',
  async (data: SelectedAffirmationAdminDTO) => {
    return (await _adminService.createSelectedAffirmations(data)).data;
  }
);

export const getCreatedAffirmations = createAsyncThunk(
  'admin-area/created-affirmations',
  async () => {
    return _adminService.getCreatedAffirmations();
  }
);

export const requestPassword = createAsyncThunk(
  'admin-area/request-password',
  async (data: EmailRequiredDto) => {
    return _adminService.requestPassword(data);
  }
);

export const restorePassword = createAsyncThunk(
  'admin-area/restore-password',
  async (data: PasswordRequiredDto) => {
    return _adminService.restorePassword(data);
  }
);

export const getAudioProfiles = createAsyncThunk(
  'admin-area/audio-profiles',
  async () => {
    return _adminService.getAudioProfiles();
  }
);

export const publishAudioProfile = createAsyncThunk(
  'admin-area/publish-audio-profile',
  async (data: PublishAudioDto) => {
    return _adminService.publishAudioProfile(data);
  }
);

export const audioProfilesReviewed = createAsyncThunk(
  'admin-area/audio-profiles-reviewed',
  async (data: AcceptPublishAudioDto) => {
    return _adminService.audioProfilesReviewed(data);
  }
);
export const getAudioProfilesToReview = createAsyncThunk(
  'admin-area/get-audio-profiles-to-review',
  async () => {
    return _adminService.getAudioProfilesToReview();
  }
);

export const getPublishedAudioProfiles = createAsyncThunk(
  'admin-area/published-audio-profiles',
  async () => {
    return _adminService.getPublishedAudioProfiles();
  }
);

export const createAudioProfile = createAsyncThunk(
  'admin-area/create-audio-profile',
  async (data: DefaultAudioAdminDTO) => {
    return _adminService.createAudioProfile(data);
  }
);

export const getAffirmationsTemplateToReview = createAsyncThunk(
  'admin-area/affirmation-templates-to-review',
  async () => {
    return _adminService.affirmationsTemplateToReview();
  }
);

export const affirmationsReviewed = createAsyncThunk(
  'admin-area/affirmation-template-reviewer',
  async (data: AcceptPublishAffirmationTempalteDto) => {
    return _adminService.affirmationsReviewed(data);
  }
);

export const publishAffirmationTemplate = createAsyncThunk(
  'admin-area/publish-affirmations-template',
  async (data: PublishAffirmationoDto) => {
    return _adminService.publishAffirmationTemplate(data);
  }
);

export const updateAudioDetails = createAsyncThunk(
  'admin-area/update-audio-details',
  async (data: UpdateAudioDto) => {
    return _adminService.updateAudioDetails(data);
  }
);

export const deleteAdminAudio = createAsyncThunk(
  'admin-area/delete-admin-audio',
  async (audioID: string) => {
    return _adminService.deleteAdminAudio(audioID);
  }
);

export const updateAffirmationDetails = createAsyncThunk(
  'admin-area/update-affirmation-details',
  async (data: UpdateAffirmationDto) => {
    return _adminService.updateAffirmationDetails(data);
  }
);

export const deleteAdminAffirmation = createAsyncThunk(
  'admin-area/delete-admin-affirmation',
  async (affirmationID: string) => {
    return _adminService.deleteAdminAffirmation(affirmationID);
  }
);

export const checkMorningAffirmations = createAsyncThunk(
  'admin-area/check-affirmations-morning',
  async (data?: CheckMorningAffirmationDTO) => {
    const reponse = await _adminService.checkMorningAffirmations(
      data ? data : undefined
    );

    return reponse.data;
  }
);

export const getDefaultAudioProfiles = createAsyncThunk<
  Partial<AudioLibraryDefault>[]
>('audioProfiles/admin', async () => {
  return (await _adminService.getDefaultAudioProfiles()).data.data;
});

export const getWaitingList = createAsyncThunk(
  'admin-area/waiting-list-users',
  async () => {
    const response = await _adminService.getWaitingList();
    return response.data;
  }
);

export const getAffirmationsLength = createAsyncThunk(
  'admin-area/check-affirmations-length',
  async () => {
    return await _adminService.checkAffirmationsLength();
  }
);

export const generatePromotionCode = createAsyncThunk(
  'admin-area/promotion-code',
  async (data: PromotionCodeData) => {
    return await _adminService.generatePromotionCode(data);
  }
);

export const getPromotionCodes = createAsyncThunk(
  'admin-area/promotion-codes',
  async () => {
    return await _adminService.getPromotionCodes();
  }
);

export const uploadSeedAffirmation = createAsyncThunk(
  'admin-area/upload-seed-affirmation',
  async (params: SeedData) => {
    return await _adminService.uploadSeedAffirmation(params);
  }
);

export const getAllSeeds = createAsyncThunk<SeeedMigrations[]>(
  'admin-area/get-all-seeds',
  async () => {
    return await _adminService.getAllSeeds();
  }
);
