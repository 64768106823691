import { Affiliation } from 'redux/affiliations/types/affiliations.interface';

import { IApiResponse } from 'types/apiResponse.interface';

import { api, baseURL } from './instance';

interface ConfirmCredentialsDTO {
  affiliateId: string;
  affiliateLinkCreation: boolean;
}

const affiliationsService = {
  getAllAffiliations: () => {
    return api.get<IApiResponse<Affiliation[]>>(`${baseURL}/affiliation`);
  },
  updateStatusReview: (
    id: string,
    statusReview: boolean,
    statusAffiliate: boolean
  ) => {
    return api.patch<IApiResponse<Affiliation>>(
      `${baseURL}/affiliation/${id}/status-review`,
      { statusReview, statusAffiliate }
    );
  },
  getAffiliationStatusByUserId: () => {
    return api.get<
      IApiResponse<{ statusCreated: boolean; statusReview: boolean }>
    >(`${baseURL}/affiliation/status`);
  },
  confirmCredentials: (confirmCredentials: ConfirmCredentialsDTO) => {
    return api.post<boolean>(
      `${baseURL}/affiliation/confirm-credentials`,
      confirmCredentials
    );
  }
};

export default affiliationsService;
