import { Form, Formik } from 'formik';
import { useAppDispatch } from 'hooks/useAppDispatch';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { Box, Button, TextField, Typography } from '@mui/material';

import { CircularLogoLoader } from 'components/CircularLogoLoader';

import { Colors } from 'design/theme';

import { requestPassword } from 'redux/admin/admin.action';

import RoutesEnum from 'types/routes.enum';

const RequestRestorePassword: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const statusReview = false;

  const validationSchema = Yup.object().shape({
    email: Yup.string().required('Incorrect Email').min(7, 'incorrect Email')
  });

  const handleSubmit = async (values: { email: string }) => {
    const data = { email: values.email };

    dispatch(requestPassword(data));

    navigate(RoutesEnum.LOGIN);
  };

  return (
    <Box
      sx={{
        maxWidth: '100%',
        width: '35.375rem',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
      }}>
      <Box
        sx={{
          width: '100%',
          maxWidth: '100%',
          margin: 'auto',
          padding: '3.6875rem',
          borderRadius: '1.5rem'
        }}>
        <Formik
          initialValues={{
            email: ''
          }}
          onSubmit={(values) => handleSubmit(values)}
          validateOnChange={false}
          validateOnBlur={false}
          validationSchema={validationSchema}>
          {(formik) => (
            <Form>
              <Box
                sx={{
                  height: '85vh',
                  maxHeight: '46.9375rem',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between'
                }}>
                <Box>
                  <Typography
                    fontFamily='"Nunito"'
                    fontSize="2rem"
                    fontWeight={500}
                    mb={'1rem'}
                    color={Colors.brown}>
                    Restore password
                  </Typography>
                  <Typography
                    fontFamily='"Nunito"'
                    width={'90%'}
                    fontSize="1.25rem"
                    fontWeight={300}
                    mb="1.5rem"
                    color={Colors.brown}>
                    We&apos;ll send you an email with instructions to restore it
                  </Typography>
                  <Box mb="1rem" mt="0.75rem">
                    <TextField
                      required
                      fullWidth
                      id="email"
                      name="email"
                      placeholder="Email *"
                      autoComplete="email"
                      color="secondary"
                      sx={{
                        fontFamily: '"Nunito"',
                        fontSize: '1rem',
                        lineHeight: '1.5rem'
                      }}
                      value={formik.values.email}
                      onChange={(e) =>
                        formik.setFieldValue('email', e.target.value)
                      }
                      error={!!formik.errors.email}
                    />
                  </Box>
                  <Box minHeight={'0.625rem'} mt={'0.125rem'}>
                    {!!formik.errors.email && (
                      <Typography
                        color="error"
                        variant="body1"
                        fontFamily='"Nunito"'
                        fontWeight={500}
                        fontSize={'0.875rem'}
                        lineHeight={'0.625rem'}>
                        Invalid email address
                      </Typography>
                    )}
                  </Box>
                </Box>
                <Box>
                  <Button
                    type="submit"
                    fullWidth
                    color="secondary"
                    variant="contained"
                    sx={{
                      mt: 2,
                      mb: 1,
                      fontSize: '1rem',
                      minHeight: '3.25rem',
                      textTransform: 'none',
                      fontFamily: '"Nunito"',
                      lineHeight: '1.25rem',
                      letterSpacing: '0.05rem',
                      fontWeight: '500',
                      color: Colors.white
                    }}>
                    {statusReview ? (
                      <CircularLogoLoader />
                    ) : (
                      'Send link to email'
                    )}
                  </Button>
                  <Box
                    display="flex"
                    flexWrap="wrap"
                    justifyContent="center"
                    gap={3}
                    mt={1}>
                    <Typography
                      fontFamily='"Nunito"'
                      fontSize={'1rem'}
                      color={Colors.brown}
                      sx={{ marginTop: '0.65rem' }}>
                      {'Did you remember your password?'}
                    </Typography>
                    <Button
                      variant="text"
                      sx={{
                        textTransform: 'none',
                        fontFamily: '"Nunito"',
                        fontSize: '1rem',
                        lineHeight: '1.5rem',
                        letterSpacing: '0.006rem',
                        fontWeight: '500',
                        marginTop: '0.25rem'
                      }}
                      color="secondary"
                      onClick={() => {
                        navigate(RoutesEnum.LOGIN);
                      }}>
                      Login
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </Box>
  );
};

export default RequestRestorePassword;
