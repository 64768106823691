import { Field, Form, Formik, FormikHelpers } from 'formik';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import React, { useState } from 'react';

import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@mui/material';

import { CircularLogoLoader } from 'components/CircularLogoLoader';

import { Colors } from 'design/theme';

import { createAudioProfile } from 'redux/admin/admin.action';
import { adminUiSelector } from 'redux/admin/admin.selector';

import { DefaultAudioAdminDTO } from 'types/admin.requests';
import { Background, Voices } from 'types/audio.enums';
import { validationSchemaAudios } from 'types/validationSchemas';

const initialValues: DefaultAudioAdminDTO = {
  voice: Voices.Alloy,
  voiceSpeed: 0.7,
  background: Background.Calming,
  backgroundVolume: 0.5,
  profileName: '',
  profileDescription: '',
  onboarding: false,
  library: false
};

const AudioHub: React.FC = () => {
  const dispatch = useAppDispatch();
  const { loading, requestError } = useAppSelector(adminUiSelector);
  const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false);
  const [audioAreaValues, setAudioAreaValues] = useState<string[]>([]);

  const handleSubmit = async (
    values: typeof initialValues,
    { resetForm }: FormikHelpers<typeof initialValues>
  ) => {
    const data: DefaultAudioAdminDTO = {
      voice: values.voice,
      voiceSpeed: values.voiceSpeed,
      background: values.background,
      backgroundVolume: values.backgroundVolume,
      profileName: values.profileName,
      profileDescription: values.profileDescription,
      onboarding: values.onboarding,
      library: values.library
    };
    const actionResult = await dispatch(createAudioProfile(data));
    if (actionResult.meta.requestStatus === 'fulfilled') {
      setShowSuccessMessage(true);
      resetForm();
      setAudioAreaValues([]);
    }
  };

  return (
    <Box sx={{ paddingLeft: '1rem', margin: 0, width: '100%' }}>
      <Typography
        sx={{
          fontWeight: 500,
          color: Colors.bgGreen,
          fontFamily: '"Nunito"',
          fontSize: '1.25em',
          marginTop: '0.5rem',
          marginBottom: '1rem'
        }}>
        Create Audio Profile
      </Typography>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchemaAudios}
        onSubmit={handleSubmit}>
        {({ values, handleChange, errors, touched, setFieldValue }) => {
          return (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    id="profileName"
                    name="profileName"
                    label="Profile Name"
                    value={values.profileName}
                    onChange={handleChange}
                    error={touched.profileName && Boolean(errors.profileName)}
                    helperText={touched.profileName && errors.profileName}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    id="profileDescription"
                    name="profileDescription"
                    label="Profile Description"
                    multiline
                    minRows={1}
                    value={values.profileDescription}
                    onChange={handleChange}
                    error={
                      touched.profileDescription &&
                      Boolean(errors.profileDescription)
                    }
                    helperText={
                      touched.profileDescription && errors.profileDescription
                    }
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <Autocomplete
                    multiple
                    id="tags-outlined"
                    options={['Onboarding', 'Library']}
                    getOptionLabel={(option) => option}
                    defaultValue={[]}
                    value={audioAreaValues}
                    onChange={(event, value) => {
                      setAudioAreaValues(value);
                      setFieldValue('onboarding', value.includes('Onboarding'));
                      setFieldValue('library', value.includes('Library'));
                    }}
                    filterSelectedOptions
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Audio Area"
                        placeholder="Select options"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl
                    fullWidth
                    error={touched.voice && !!errors.voice}>
                    <InputLabel>Voice</InputLabel>
                    <Field
                      name="voice"
                      as={Select}
                      label="Voice"
                      onChange={handleChange}
                      value={values.voice}>
                      {Object.values(Voices).map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Field>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    id="voiceSpeed"
                    name="voiceSpeed"
                    label="Voice Speed"
                    type="number"
                    value={values.voiceSpeed}
                    onChange={handleChange}
                    error={touched.voiceSpeed && Boolean(errors.voiceSpeed)}
                    helperText={touched.voiceSpeed && errors.voiceSpeed}
                    inputProps={{ step: 0.05, min: 0.7, max: 1.1 }}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <FormControl
                    fullWidth
                    error={touched.background && !!errors.background}>
                    <InputLabel>Background</InputLabel>
                    <Field
                      name="background"
                      as={Select}
                      label="Background"
                      onChange={handleChange}
                      value={values.background}>
                      {Object.values(Background).map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Field>
                  </FormControl>
                </Grid>
                {/* Background Volume Input */}
                <Grid item xs={12} md={6} mb={3}>
                  <TextField
                    fullWidth
                    id="backgroundVolume"
                    name="backgroundVolume"
                    label="Background Volume"
                    type="number"
                    value={values.backgroundVolume}
                    onChange={handleChange}
                    error={
                      touched.backgroundVolume &&
                      Boolean(errors.backgroundVolume)
                    }
                    helperText={
                      touched.backgroundVolume && errors.backgroundVolume
                    }
                    inputProps={{ step: 0.05, min: 0, max: 1.0 }}
                  />
                </Grid>

                <>
                  {requestError && (
                    <Typography color="error">{requestError}</Typography>
                  )}
                  {loading ? (
                    <Box
                      sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignContent: 'center'
                      }}>
                      <CircularLogoLoader />
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignContent: 'center'
                      }}>
                      <Button
                        disabled={!values.library && !values.onboarding}
                        type="submit"
                        variant="contained"
                        sx={{ mt: 2, mb: 1 }}>
                        Create Audio Profile
                      </Button>
                    </Box>
                  )}
                  {!loading && showSuccessMessage && (
                    <Typography color="primary">
                      Audio generated successfully!
                    </Typography>
                  )}
                </>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};

export default AudioHub;
