import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import React, { useState } from 'react';

import { Box, Grid, Typography } from '@mui/material';

import { Colors } from 'design/theme';

import {
  affirmationsReviewed,
  checkMorningAffirmations,
  getAffirmationsLength,
  getAffirmationsTemplateToReview,
  getDefaultAudioProfiles
} from 'redux/admin/admin.action';
import { adminSelector } from 'redux/admin/admin.selector';

import { AffirmationProduct, DefaultAudioAdminDTO } from 'types/admin.requests';
import { CheckMorningAffirmationDTO } from 'types/apiResponse.interface';

import { AcceptAffirmationDialog } from './AcceptAffirmationDialog';
import AffirmationCard from './AffirmationCard';
import AffirmationsModal from './AffirmationsModal';
import ConfirmDialogs from './ConfirmDialogs';
import EditAffirmationModal from './EditAffirmationModal';

export const ReviewAffirmations: React.FC = () => {
  const dispatch = useAppDispatch();
  const affirmationTemplates =
    useAppSelector(adminSelector).affirmations.affirmationsReview;

  const [open, setOpen] = useState<boolean>(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [currentAffirmationId, setCurrentAffirmationId] = useState<
    string | null
  >(null);
  const [selectedAffirmations, setSelectedAffirmations] = useState<
    { id: number; affirmationSentence: string }[]
  >([]);
  const [audioProfileData, setAudioProfileData] =
    useState<DefaultAudioAdminDTO>();
  const [openModal, setOpenModal] = useState(false);
  const [currentAffirmationData, setCurrentAffirmationData] =
    useState<AffirmationProduct>({} as AffirmationProduct);
  const [publishConfirmDialog, setPublishConfirmDialog] =
    useState<boolean>(false);
  let overWriteValue: boolean = false;
  const [currentAffirmationDevArea, setCurrentAffirmationDevArea] =
    useState<string>('');
  const handleOpenDialog = (audioId: string, devArea: string) => {
    dispatch(getDefaultAudioProfiles());
    setCurrentAffirmationDevArea(devArea);
    setCurrentAffirmationId(audioId);
    setOpenDialog(true);
  };
  const { affirmations } = useAppSelector(adminSelector);
  const affirmationsPublished = affirmations.affirmationsPublished;
  const [openFeaturedDialog, setOpenFeaturedDialog] = useState<boolean>(false);

  const handleOpen = (
    affirmations: { id: number; affirmationSentence: string }[]
  ) => {
    setSelectedAffirmations(affirmations);
    setOpen(true);
  };
  const sortedAffirmationTemplates = (affirmationTemplates || [])
    .map((template) => ({ ...template }))
    .sort((a, b) => {
      if (a.Published === b.Published) {
        return a.review ? 1 : -1;
      }
      return a.Published ? 1 : -1;
    });

  const MorningPublished = (affirmationTemplates || [])
    .filter((product) => product.developmentArea === 'Morning')
    .some((product) => product.Published);

  const handleConfirmPublish = async (status: boolean) => {
    if (affirmationsPublished && affirmationsPublished >= 4) {
      setOpenFeaturedDialog(true);
    } else {
      if (
        currentAffirmationId &&
        (currentAffirmationDevArea === 'Featured' || !MorningPublished)
      ) {
        await dispatch(
          affirmationsReviewed({
            affirmationID: currentAffirmationId,
            audioProfile:
              currentAffirmationDevArea === 'Morning'
                ? audioProfileData
                : undefined,
            status: status
          })
        );
        const result = await dispatch(getAffirmationsTemplateToReview());
        if (getAffirmationsTemplateToReview.fulfilled.match(result)) {
          setOpenDialog(false);
          dispatch(getAffirmationsLength());
        }
      } else {
        setPublishConfirmDialog(true);
      }
    }
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedAffirmations([]);
  };
  const handleCloseDialog = async () => {
    const result = await dispatch(getAffirmationsTemplateToReview());
    if (getAffirmationsTemplateToReview.fulfilled.match(result)) {
      setOpenDialog(false);
    }
  };

  const handleOpenModal = (affirmation: AffirmationProduct) => {
    setCurrentAffirmationData(affirmation);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleConfirmDialog = async (value: boolean) => {
    overWriteValue = true;
    if (currentAffirmationId) {
      const data: CheckMorningAffirmationDTO = {
        newMorningAffirmationId: currentAffirmationId,
        overwrite: overWriteValue
      };
      await dispatch(
        affirmationsReviewed({
          affirmationID: currentAffirmationId,
          audioProfile:
            currentAffirmationDevArea === 'Morning'
              ? audioProfileData
              : undefined,
          status: true
        })
      );
      await dispatch(checkMorningAffirmations(data));
    }
    handleCloseDialog();
    setPublishConfirmDialog(value);
  };

  const handleCloseConfirmDialog = () => {
    setPublishConfirmDialog(false);
  };

  const handleCloseFeaturedDialog = () => {
    setOpenFeaturedDialog(false);
  };

  const handleConfirmFeaturedDialog = async () => {
    if (currentAffirmationId) {
      await dispatch(
        affirmationsReviewed({
          affirmationID: currentAffirmationId,
          audioProfile:
            currentAffirmationDevArea === 'Morning'
              ? audioProfileData
              : undefined,
          status: true,
          overWrite: true
        })
      );
    }
    setOpenFeaturedDialog(false);
    handleCloseDialog();
  };

  if (sortedAffirmationTemplates.length < 1) {
    return (
      <Box sx={{ minHeight: '20rem' }}>
        <Box
          sx={{
            margin: 'auto',
            background: 'rgba(53, 101, 105, 0.3)',
            height: '8rem',
            display: 'flex',
            alignItems: 'center',
            borderRadius: '0.25rem',
            justifyContent: 'center',
            flexDirection: 'column'
          }}>
          <Typography
            fontFamily='"Nunito"'
            fontSize={'1.25rem'}
            color={Colors.bgGreen}
            sx={{
              width: '70%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '0.25rem',
              textAlign: 'center',
              gap: '1rem'
            }}>
            No Templates to Review
          </Typography>
        </Box>
      </Box>
    );
  }

  return (
    <Box sx={{ paddingLeft: '1rem', margin: 0, width: '100%' }}>
      <Typography
        sx={{
          fontWeight: 500,
          color: Colors.bgGreen,
          fontFamily: '"Nunito"',
          fontSize: '1.25em',
          marginTop: '0.5rem'
        }}>
        Review Affirmations Templates
      </Typography>
      <Grid container spacing={2} mt={0}>
        {sortedAffirmationTemplates.map((affirmation, index) => (
          <Grid item xs={12} md={4} key={affirmation.id}>
            <AffirmationCard
              key={index}
              affirmation={affirmation}
              handleOpen={handleOpen}
              isReview={true}
              handleOpenDialog={handleOpenDialog}
              handleOpenModal={handleOpenModal}
            />
          </Grid>
        ))}
      </Grid>
      <EditAffirmationModal
        open={openModal}
        handleCloseModal={handleCloseModal}
        currentAffirmationData={currentAffirmationData}
        isReview={true}
      />
      <AffirmationsModal
        open={open}
        handleClose={handleClose}
        selectedAffirmations={selectedAffirmations}
      />
      <AcceptAffirmationDialog
        open={openDialog}
        onClose={handleCloseDialog}
        onConfirm={handleConfirmPublish}
        currentAffirmationDevArea={currentAffirmationDevArea}
        setAudioProfileData={setAudioProfileData}
      />
      <ConfirmDialogs
        publishConfirmDialog={publishConfirmDialog}
        openFeaturedDialog={openFeaturedDialog}
        handleConfirmDialog={handleConfirmDialog}
        handleCloseConfirmDialog={handleCloseConfirmDialog}
        handleCloseFeaturedDialog={handleCloseFeaturedDialog}
        handleConfirmFeaturedDialog={handleConfirmFeaturedDialog}
      />
    </Box>
  );
};
