import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import useIsLargeScreen from 'hooks/useIsLargeScreen';
import React, { useEffect, useRef, useState } from 'react';

import { Add } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Skeleton,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';

import { Colors } from 'design/theme';

import { deleteAdmin, getAdmins } from 'redux/admin/admin.action';
import { adminSelector, adminUsersSelector } from 'redux/admin/admin.selector';

import { AdminRoles } from 'types/roles.enum';

import { CreateAdminModal } from '../CreateAdminModal';
import {
  StyledTableCell,
  StyledTableContainer,
  StyledTableHeadCell
} from '../Users/UsersPanel';

const AdminPanel: React.FC = () => {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState<boolean>(false);
  const admins = useAppSelector(adminUsersSelector)?.admins;
  const adminInfo = useAppSelector(adminSelector).ui.adminInfo;
  const handleOpenModal = () => setOpen(true);
  const handleCloseModal = () => setOpen(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const [selectedAdminId, setSelectedAdminId] = useState<string | null>(null);
  const isLargeScreen = useIsLargeScreen();

  const handleOpenDeleteDialog = (adminId: string) => {
    setSelectedAdminId(adminId);
    setDeleteDialogOpen(true);
  };
  const handleCloseDeleteDialog = async () => {
    const result = await dispatch(getAdmins());
    if (getAdmins.fulfilled.match(result)) {
      setDeleteDialogOpen(false);
    }
  };
  const handleDeleteAdmin = async () => {
    if (selectedAdminId) {
      await dispatch(deleteAdmin(selectedAdminId));
      handleCloseDeleteDialog();
    }
  };

  const prevOpenRef = useRef(open);
  useEffect(() => {
    const prevOpen = prevOpenRef.current;
    prevOpenRef.current = open;
    if (prevOpen && !open) {
      dispatch(getAdmins());
    }
  }, [open, dispatch]);

  if (!admins) {
    return (
      <StyledTableContainer>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <StyledTableHeadCell>Name</StyledTableHeadCell>
              <StyledTableHeadCell align="center">Email</StyledTableHeadCell>
              <StyledTableHeadCell align="center">Role</StyledTableHeadCell>
              <StyledTableHeadCell align="center">
                Created by
              </StyledTableHeadCell>
              {adminInfo?.subscriptionType === AdminRoles.SUPERADMIN && (
                <StyledTableHeadCell align="center">Delete</StyledTableHeadCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {[...Array(4)].map((_, index) => (
              <TableRow key={index}>
                <StyledTableCell component="th" scope="row">
                  <Skeleton variant="text" width="100%" />
                </StyledTableCell>
                <StyledTableCell align="right">
                  <Skeleton variant="text" width="100%" />
                </StyledTableCell>
                <StyledTableCell align="right">
                  <Skeleton variant="text" width="100%" />
                </StyledTableCell>
                <StyledTableCell align="right">
                  <Skeleton variant="text" width="100%" />
                </StyledTableCell>
                {adminInfo?.subscriptionType === AdminRoles.SUPERADMIN && (
                  <StyledTableCell align="right">
                    <Skeleton variant="text" width="100%" />
                  </StyledTableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </StyledTableContainer>
    );
  }
  return (
    <Box
      sx={{
        paddingLeft: isLargeScreen ? '1rem' : '0',
        margin: 0,
        width: '100%'
      }}>
      <Box
        sx={{
          marginTop: '0.5rem',
          marginBottom: '1rem',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between'
        }}>
        <Typography
          fontWeight={500}
          color={Colors.bgGreen}
          fontFamily={'"Nunito"'}
          fontSize={'1.25em'}>
          Admins dashboard
        </Typography>
        <Button
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleOpenModal}
          sx={{ backgroundColor: Colors.secondary.main }}
          variant="contained"
          disabled={!admins}>
          <Add fontSize="small" />
          {isLargeScreen && 'Create new'}
        </Button>
      </Box>
      <StyledTableContainer>
        <Table
          size={isLargeScreen ? 'medium' : 'small'}
          aria-label="simple table">
          <TableHead>
            <TableRow>
              {isLargeScreen && <StyledTableHeadCell>Name</StyledTableHeadCell>}
              <StyledTableHeadCell align="center">Email</StyledTableHeadCell>
              <StyledTableHeadCell align="center">Role</StyledTableHeadCell>
              {isLargeScreen && (
                <StyledTableHeadCell align="center">
                  Created by
                </StyledTableHeadCell>
              )}
              {adminInfo?.subscriptionType === AdminRoles.SUPERADMIN && (
                <StyledTableHeadCell align="center">Delete</StyledTableHeadCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {admins.map((admin) => (
              <TableRow key={admin.email}>
                {isLargeScreen && (
                  <StyledTableCell>{admin.name}</StyledTableCell>
                )}
                <StyledTableCell align="center" sx={{ overflow: 'auto' }}>
                  {admin.email}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {admin.subscriptionType === AdminRoles.ADMIN
                    ? 'Admin'
                    : 'Superadmin'}
                </StyledTableCell>
                {isLargeScreen && (
                  <StyledTableCell align="center">
                    {admin.createdBy}
                  </StyledTableCell>
                )}
                {adminInfo?.subscriptionType === AdminRoles.SUPERADMIN && (
                  <StyledTableCell align="center">
                    <IconButton
                      onClick={() => handleOpenDeleteDialog(admin.adminID)}>
                      <DeleteIcon />
                    </IconButton>
                  </StyledTableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </StyledTableContainer>
      <Dialog open={deleteDialogOpen} onClose={handleCloseDeleteDialog}>
        <DialogTitle>Delete Admin</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this admin? This action cannot be
            undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog}>Cancel</Button>
          <Button onClick={handleDeleteAdmin} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <CreateAdminModal openModal={open} onCloseModal={handleCloseModal} />
    </Box>
  );
};

export default AdminPanel;
