import React from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';

interface ConfirmDialogsProps {
  publishConfirmDialog: boolean;
  openFeaturedDialog: boolean;
  handleConfirmDialog: (value: boolean) => void;
  handleCloseConfirmDialog: () => void;
  handleCloseFeaturedDialog: () => void;
  handleConfirmFeaturedDialog: () => void;
}

const ConfirmDialogs: React.FC<ConfirmDialogsProps> = ({
  publishConfirmDialog,
  openFeaturedDialog,
  handleConfirmDialog,
  handleCloseConfirmDialog,
  handleCloseFeaturedDialog,
  handleConfirmFeaturedDialog
}) => {
  return (
    <>
      <Dialog
        open={publishConfirmDialog}
        onClose={() => handleConfirmDialog(false)}
        aria-labelledby="confirm-delete-dialog-title"
        aria-describedby="confirm-delete-dialog-description">
        <DialogTitle id="confirm-delete-dialog-title">
          Confirm Publish
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="confirm-delete-dialog-description">
            We already have a morning affirmation. Would you like to replace it
            with the new one?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleCloseConfirmDialog()} color="primary">
            Cancel
          </Button>
          <Button onClick={() => handleConfirmDialog(false)} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openFeaturedDialog}
        onClose={() => handleConfirmDialog(false)}
        aria-labelledby="confirm-delete-dialog-title"
        aria-describedby="confirm-delete-dialog-description">
        <DialogTitle id="confirm-delete-dialog-title">
          Confirm Publish
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="confirm-delete-dialog-description">
            Do you want to replace the oldest featured affirmation?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleCloseFeaturedDialog()} color="primary">
            Cancel
          </Button>
          <Button onClick={() => handleConfirmFeaturedDialog()} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ConfirmDialogs;
