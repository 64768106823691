import { useAppSelector } from 'hooks/useAppSelector';
import useIsLargeScreen from 'hooks/useIsLargeScreen';
import React from 'react';

import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled
} from '@mui/material';

import { Colors } from 'design/theme';

import { adminUsersSelector } from 'redux/admin/admin.selector';

export const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  borderRadius: '10px',
  border: `1px solid ${Colors.shadowWhite}`,
  overflow: 'hidden',
  boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
  backgroundColor: Colors.shadowWhite
}));

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontFamily: '"Nunito"',
  fontWeight: 500,
  maxWidth: '3rem'
}));

export const StyledTableHeadCell = styled(StyledTableCell)(({ theme }) => ({
  backgroundColor: Colors.bgGreen,
  color: '#ffffff'
}));

export const UsersPanel: React.FC = () => {
  const users = useAppSelector(adminUsersSelector)?.users;
  const isLargeScreen = useIsLargeScreen();
  if (!users) {
    return (
      <>
        <Box sx={{ marginTop: '0.5rem', marginBottom: '1rem' }}>
          <Typography
            fontWeight={500}
            color={Colors.bgGreen}
            fontFamily={'"Nunito"'}
            fontSize={'1.25em'}>
            Not Users Yet
          </Typography>
        </Box>
        <StyledTableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <StyledTableHeadCell>Email</StyledTableHeadCell>
                <StyledTableHeadCell align="center">
                  Country
                </StyledTableHeadCell>
                <StyledTableHeadCell align="center">
                  Onboarding
                </StyledTableHeadCell>
                <StyledTableHeadCell align="center">
                  Subscription
                </StyledTableHeadCell>
                <StyledTableHeadCell align="center">
                  Subscription Status
                </StyledTableHeadCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <StyledTableCell component="th" scope="row">
                  {'- - -'}
                </StyledTableCell>
                <StyledTableCell align="center"> {'- - -'}</StyledTableCell>
                {isLargeScreen && (
                  <StyledTableCell align="center">{'- - -'}</StyledTableCell>
                )}
                {isLargeScreen && (
                  <StyledTableCell align="center">{'- - -'}</StyledTableCell>
                )}
                {isLargeScreen && (
                  <StyledTableCell align="center">{'- - -'}</StyledTableCell>
                )}
              </TableRow>
            </TableBody>
          </Table>
        </StyledTableContainer>
      </>
    );
  }
  return (
    <Box
      sx={{
        paddingLeft: isLargeScreen ? '1rem' : '0',
        margin: 0,
        width: '100%'
      }}>
      <Box sx={{ marginTop: '0.5rem', marginBottom: '1rem' }}>
        <Typography
          fontWeight={500}
          color={Colors.bgGreen}
          fontFamily={'"Nunito"'}
          fontSize={'1.25em'}>
          Users dashboard
        </Typography>
      </Box>

      <StyledTableContainer>
        <Table
          size={isLargeScreen ? 'medium' : 'small'}
          aria-label="simple table">
          <TableHead>
            <TableRow>
              <StyledTableHeadCell align="center">Email</StyledTableHeadCell>
              {isLargeScreen && (
                <StyledTableHeadCell align="center">
                  Country
                </StyledTableHeadCell>
              )}
              {isLargeScreen && (
                <StyledTableHeadCell align="center">
                  Onboarding
                </StyledTableHeadCell>
              )}
              <StyledTableHeadCell align="center">
                Subscription
              </StyledTableHeadCell>
              <StyledTableHeadCell align="center">
                Subscription Status
              </StyledTableHeadCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user) => (
              <TableRow key={user.stripeCustomerId}>
                <StyledTableCell
                  component="th"
                  scope="row"
                  sx={{ overflow: 'auto' }}>
                  {user.email}
                </StyledTableCell>
                {isLargeScreen && (
                  <StyledTableCell align="center">
                    {user.country}
                  </StyledTableCell>
                )}
                {isLargeScreen && (
                  <StyledTableCell align="center">
                    {user.onBoarding ? 'True' : 'False'}
                  </StyledTableCell>
                )}
                <StyledTableCell align="center">
                  {user.subscription}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {user.subscriptionStatus === 'Active'
                    ? user.subscriptionStatus
                    : 'Inactive'}
                </StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </StyledTableContainer>
    </Box>
  );
};

export default UsersPanel;
