import { ReactComponent as Logo } from 'assets/images/iAffirm-logo.svg';
import { ReactComponent as LogoText } from 'assets/images/iAffirm-text.svg';
import { Form, Formik } from 'formik';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { Visibility } from '@mui/icons-material';
import { Box, Button, IconButton, TextField, Typography } from '@mui/material';

import { CircularLogoLoader } from 'components/CircularLogoLoader';

import { Colors } from 'design/theme';

import { adminLogin } from 'redux/admin/admin.action';
import { adminSelector } from 'redux/admin/admin.selector';

import loginRequestType from 'types/loginRequest.type';
import RoutesEnum from 'types/routes.enum';

const LoginPage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { ui } = useSelector(adminSelector);

  const [showPassword, setShowPassword] = useState(false);

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required('Usuario es requerido')
      .min(7, 'Usuario incorrecto'),
    password: Yup.string().required('Contraseña requerida').min(4, '4')
  });

  const handleSubmit = async (values: loginRequestType) => {
    const user = await dispatch(adminLogin(values));
    if (user.meta.requestStatus === 'fulfilled') navigate(RoutesEnum.USER_HOME);
  };

  return (
    <Box
      sx={{
        maxWidth: '100%',
        width: '35.375rem',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
      }}>
      <Box
        sx={{
          width: '100%',
          maxWidth: '100%',
          margin: 'auto',
          padding: '3.6875rem',
          borderRadius: '1.5rem'
        }}>
        <Formik
          initialValues={{
            email: '',
            password: ''
          }}
          onSubmit={(values) => handleSubmit(values)}
          validateOnChange={false}
          validateOnBlur={false}
          validationSchema={validationSchema}>
          {(formik) => (
            <Form>
              <Box
                sx={{
                  height: '85vh',
                  maxHeight: '46.9375rem',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between'
                }}>
                <Box>
                  <Link to={RoutesEnum.USER_HOME}>
                    <Box
                      display="flex"
                      columnGap={'1rem'}
                      minWidth={'min-content'}
                      alignItems="center"
                      sx={{ cursor: 'pointer' }}>
                      <Logo />
                      <LogoText />
                    </Box>
                  </Link>
                  <Typography
                    mt="4.125rem"
                    fontSize="2rem"
                    fontWeight={500}
                    color={Colors.secondary.dark}>
                    Admin Login
                  </Typography>
                  <Box mb="1rem" mt="0.75rem">
                    <TextField
                      color="secondary"
                      required
                      fullWidth
                      id="email"
                      name="email"
                      placeholder="Email *"
                      autoComplete="email"
                      sx={{
                        fontFamily: '"Nunito"',
                        fontSize: '1rem',
                        lineHeight: '1.5rem'
                      }}
                      value={formik.values.email}
                      onChange={(e) =>
                        formik.setFieldValue('email', e.target.value)
                      }
                      error={!!formik.errors.email}
                    />
                  </Box>
                  <Box mb="1rem" mt="0.75rem">
                    <TextField
                      color="secondary"
                      required
                      fullWidth
                      placeholder="Password *"
                      name="password"
                      type={!showPassword ? 'password' : ''}
                      id="password"
                      autoComplete="current-password"
                      value={formik.values.password}
                      onChange={(e) =>
                        formik.setFieldValue('password', e.target.value)
                      }
                      sx={{
                        fontFamily: '"Nunito"',
                        fontSize: '1rem',
                        lineHeight: '1.5rem'
                      }}
                      error={!!formik.errors.password}
                      InputProps={{
                        endAdornment: (
                          <IconButton
                            onMouseEnter={() => setShowPassword(true)}
                            onMouseLeave={() => setShowPassword(false)}>
                            <Visibility />
                          </IconButton>
                        )
                      }}
                    />
                  </Box>
                  <Box minHeight={'1rem'} mt={'0.125rem'}>
                    {!!formik.errors.email ||
                      (!!formik.errors.password && (
                        <Typography
                          color="error"
                          variant="body1"
                          fontFamily='"Nunito"'
                          fontWeight={500}
                          fontSize={'0.875rem'}
                          lineHeight={'1rem'}>
                          Datos ingresados incorrectos.
                        </Typography>
                      ))}
                    {ui.requestError && (
                      <Typography
                        color="error"
                        variant="body1"
                        fontWeight={500}
                        fontSize={'0.875rem'}
                        lineHeight={'1rem'}>
                        {ui.requestError}
                      </Typography>
                    )}
                  </Box>
                  <Button
                    variant="text"
                    sx={{
                      textTransform: 'none',
                      fontFamily: '"Nunito"',
                      fontSize: '1rem',
                      lineHeight: '1.5rem',
                      letterSpacing: '0.05rem',
                      fontWeight: '500'
                    }}
                    color="secondary"
                    onClick={() => navigate(RoutesEnum.RESTORE_REQUEST)}>
                    Restore password
                  </Button>
                </Box>
                <Box>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="secondary"
                    sx={{
                      mt: 2,
                      mb: 1,
                      fontSize: '1rem',
                      minHeight: '3.25rem',
                      textTransform: 'none',
                      fontFamily: '"Nunito"',
                      lineHeight: '1.25rem',
                      letterSpacing: '0.05rem',
                      fontWeight: '500',
                      color: Colors.white
                    }}>
                    {false ? <CircularLogoLoader /> : 'Login'}
                  </Button>
                </Box>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </Box>
  );
};
export default LoginPage;
