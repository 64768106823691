import { useAppDispatch } from 'hooks/useAppDispatch';
import React, { useEffect, useState } from 'react';

import { Box, Button, Modal, TextField, Typography } from '@mui/material';

import ConfirmDeleteDialog from 'components/ConfirmDeleteDialog';

import { Colors } from 'design/theme';

import {
  deleteAdminAffirmation,
  getAffirmationsTemplateToReview,
  getCreatedAffirmations,
  updateAffirmationDetails
} from 'redux/admin/admin.action';

import { AffirmationProduct, UpdateAffirmationDto } from 'types/admin.requests';

interface EditAffirmationsModalProps {
  open: boolean;
  handleCloseModal: () => void;
  currentAffirmationData: AffirmationProduct;
  isReview?: boolean;
}

interface UpdatedAffirmationInfo {
  title?: string;
}

const EditAffirmationsModal: React.FC<EditAffirmationsModalProps> = ({
  open,
  handleCloseModal,
  currentAffirmationData,
  isReview
}) => {
  const dispatch = useAppDispatch();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [affirmationInfo, setAffirmationInfo] =
    useState<UpdatedAffirmationInfo>({});
  const [openCofirmDialog, setOpenConfirmDialog] = useState<boolean>(false);

  const hasChanges =
    JSON.stringify({
      title: currentAffirmationData.title
    }) !== JSON.stringify(affirmationInfo);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAffirmationInfo({
      ...affirmationInfo,
      [event.target.name]: event.target.value
    });
  };

  const handleOpenDeleteDialog = () => {
    setOpenConfirmDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenConfirmDialog(false);
  };
  const saveChanges = async () => {
    if (hasChanges) {
      setIsSubmitting(true);
      const updateData: UpdateAffirmationDto = {
        id: currentAffirmationData.id,
        title: affirmationInfo.title
      };

      const result = await dispatch(updateAffirmationDetails(updateData));
      if (updateAffirmationDetails.fulfilled.match(result)) {
        handleCloseModal();
        dispatch(getAffirmationsTemplateToReview());
        dispatch(getCreatedAffirmations());
        setIsSubmitting(false);
      }
    }
  };
  const handleCancel = () => {
    handleCloseModal();
  };

  const handleDelete = async () => {
    const result = await dispatch(
      deleteAdminAffirmation(currentAffirmationData.id)
    );
    if (deleteAdminAffirmation.fulfilled.match(result)) {
      handleCloseModal();
      dispatch(getAffirmationsTemplateToReview());
      dispatch(getCreatedAffirmations());
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    setAffirmationInfo({
      title: currentAffirmationData?.title || ''
    });
  }, [currentAffirmationData, dispatch]);

  return (
    <>
      <Modal
        open={open}
        onClose={handleCloseModal}
        aria-labelledby="affirmations-modal-title"
        aria-describedby="affirmations-modal-description">
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '50%',
            maxHeight: '75%',
            bgcolor: 'background.paper',
            border: `2px solid ${Colors.secondary.main}`,
            borderRadius: '2rem',
            boxShadow: 24,
            p: 4
          }}>
          <Typography id="affirmations-modal-title" variant="h6" component="h2">
            Review your Affirmation Details
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              minHeight: '10rem',
              justifyContent: 'space-around'
            }}>
            <TextField
              color="secondary"
              fullWidth
              value={affirmationInfo.title}
              onChange={handleChange}
              name="title"
              id="title"
              label="Title"
              placeholder="Title *"
              autoComplete="title"
              sx={{
                fontFamily: '"Nunito"',
                fontWeight: '500',
                color: Colors.brown.main
              }}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%'
            }}>
            <Button sx={{ color: 'red' }} onClick={handleOpenDeleteDialog}>
              Delete
            </Button>
            <Box>
              <Button sx={{ marginRight: '0.5rem' }} onClick={handleCancel}>
                Cancel
              </Button>
              <Button
                disabled={!hasChanges || isSubmitting}
                onClick={saveChanges}>
                Save
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
      <ConfirmDeleteDialog
        open={openCofirmDialog}
        onClose={handleCloseDeleteDialog}
        onConfirm={handleDelete}
      />
    </>
  );
};

export default EditAffirmationsModal;
