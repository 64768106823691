import React from 'react';

import { Box, Button, Modal, Typography } from '@mui/material';

import { Colors } from 'design/theme';

interface AffirmationsModalProps {
  open: boolean;
  handleClose: () => void;
  selectedAffirmations: { id: number; affirmationSentence: string }[];
}

const AffirmationsModal: React.FC<AffirmationsModalProps> = ({
  open,
  handleClose,
  selectedAffirmations
}) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="affirmations-modal-title"
      aria-describedby="affirmations-modal-description">
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '80%',
          maxHeight: '75%',
          bgcolor: 'background.paper',
          border: `2px solid ${Colors.secondary.main}`,
          borderRadius: '2rem',
          boxShadow: 24,
          p: 4
        }}>
        <Typography id="affirmations-modal-title" variant="h6" component="h2">
          Affirmation Sentences
        </Typography>
        <Box sx={{ margin: '1rem' }}>
          <Box
            id="affirmations-modal-description"
            sx={{ mt: 2, maxHeight: '50vh', overflowY: 'auto' }}>
            {selectedAffirmations.map((affirmation, index) => (
              <Box
                key={index}
                sx={{
                  background: Colors.secondary.light,
                  borderRadius: '0.5rem',
                  padding: '0.25rem',
                  margin: '0.5rem'
                }}>
                <Typography
                  key={affirmation.id}
                  variant="body1"
                  sx={{ margin: 1 }}>
                  {affirmation.affirmationSentence}
                </Typography>
              </Box>
            ))}
          </Box>
          <Button onClick={handleClose} sx={{ mt: 2 }}>
            Close
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default AffirmationsModal;
