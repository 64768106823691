import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import useIsLargeScreen from 'hooks/useIsLargeScreen';
import React, { useState } from 'react';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  Skeleton,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@mui/material';

import {
  StyledTableCell,
  StyledTableContainer,
  StyledTableHeadCell
} from 'components/Users/UsersPanel';

import { Colors } from 'design/theme';

import { getAllSeeds, uploadSeedAffirmation } from 'redux/admin/admin.action';
import { adminSelector } from 'redux/admin/admin.selector';

import { SeedData, SeedType } from 'types/apiResponse.interface';

export const AffirmationSeed = () => {
  const dispatch = useAppDispatch();
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [uploadSuccess, setUploadSuccess] = useState<boolean>(false);
  const [uploadPDF, setUploadPDF] = useState<boolean>(false);
  const seeds = useAppSelector(adminSelector)?.seeds;
  const isLargeScreen = useIsLargeScreen();

  const affirmationSeeds = seeds?.filter(
    (item) => item.seedType === SeedType.Affirmation
  );

  const handleFileChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    setFile: React.Dispatch<React.SetStateAction<File | null>>,
    setUploadSuccess: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    if (event.target.files && event.target.files[0]) {
      setFile(event.target.files[0]);
      setUploadSuccess(false);
    }
  };

  const handleSubmitSecondID = async (event: React.FormEvent) => {
    event.preventDefault();
    if (!selectedFile) return;

    setUploadPDF(true);
    const params: SeedData = {
      file: selectedFile,
      seedType: SeedType.Affirmation
    };
    await dispatch(uploadSeedAffirmation(params));
    setUploadPDF(false);
    setUploadSuccess(true);
    dispatch(getAllSeeds());
  };
  if (!seeds) {
    return (
      <StyledTableContainer>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <StyledTableHeadCell>File Name</StyledTableHeadCell>
              <StyledTableHeadCell align="center">
                Seed Type
              </StyledTableHeadCell>
              <StyledTableHeadCell align="center">Admin</StyledTableHeadCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {[...Array(4)].map((_, index) => (
              <TableRow key={index}>
                <StyledTableCell component="th" scope="row">
                  <Skeleton variant="text" width="100%" />
                </StyledTableCell>
                <StyledTableCell align="right">
                  <Skeleton variant="text" width="100%" />
                </StyledTableCell>
                <StyledTableCell align="right">
                  <Skeleton variant="text" width="100%" />
                </StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </StyledTableContainer>
    );
  }

  return (
    <Box sx={{ paddingLeft: '1rem', margin: 0, width: '100%' }}>
      {affirmationSeeds && (
        <Box>
          <Typography
            sx={{
              fontWeight: 500,
              color: Colors.bgGreen,
              fontFamily: '"Nunito"',
              fontSize: '1.25em',
              marginTop: '0.5rem',
              marginBottom: '1rem'
            }}>
            Affirmations Seed Migrations
          </Typography>
          <StyledTableContainer>
            <Table
              size={isLargeScreen ? 'medium' : 'small'}
              aria-label="simple table">
              <TableHead>
                <TableRow>
                  <StyledTableHeadCell align="center">
                    File Name
                  </StyledTableHeadCell>
                  <StyledTableHeadCell align="center">
                    Seed Type
                  </StyledTableHeadCell>
                  <StyledTableHeadCell align="center">
                    Admin
                  </StyledTableHeadCell>
                </TableRow>
              </TableHead>
            </Table>
          </StyledTableContainer>
          <StyledTableContainer
            sx={{
              maxHeight: '20rem',
              overflow: affirmationSeeds.length >= 5 ? 'auto' : undefined
            }}>
            <Table>
              <TableBody>
                {affirmationSeeds.length > 0 ? (
                  affirmationSeeds?.map((seed, index) => (
                    <TableRow key={index}>
                      <StyledTableCell
                        component="th"
                        scope="row"
                        sx={{ overflow: 'hidden' }}>
                        {seed.fileName}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {seed.seedType}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {seed.adminUser && seed.adminUser.name
                          ? seed.adminUser.name
                          : 'Not available'}
                      </StyledTableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow sx={{ cursor: 'pointer' }}>
                    <StyledTableCell align="center">{'- - -'}</StyledTableCell>
                    <StyledTableCell align="center">{'- - -'}</StyledTableCell>
                    <StyledTableCell align="center">{'- - -'}</StyledTableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </StyledTableContainer>
        </Box>
      )}
      <Box
        sx={{ marginTop: '2rem', borderTop: `1px solid ${Colors.lightGrey}` }}>
        <Typography
          sx={{
            fontWeight: 500,
            color: Colors.bgGreen,
            fontFamily: '"Nunito"',
            fontSize: '1.25em',
            marginTop: '1rem'
          }}>
          Upload an affirmation seed
        </Typography>
        <Box component="form" onSubmit={handleSubmitSecondID}>
          <Grid
            container
            spacing={2}
            pt={2}
            alignItems="center"
            justifyContent="flex-start"
            gap={2}>
            <Grid item xs={12} sm={12} md={8}>
              <TextField
                label="Seed"
                variant="outlined"
                fullWidth
                InputProps={{
                  readOnly: true,
                  startAdornment: (
                    <Typography fontSize={'1rem'} sx={{ width: '100%' }}>
                      Upload an affirmation seed file
                    </Typography>
                  )
                }}
              />
            </Grid>
            <Grid item xs={12} sm={3} md={2} textAlign="center">
              <Box sx={{ display: 'flex', gap: '1rem' }}>
                <Box>
                  <FormControl>
                    <input
                      type="file"
                      accept="application/csv"
                      onChange={(event) =>
                        handleFileChange(
                          event,
                          setSelectedFile,
                          setUploadSuccess
                        )
                      }
                      style={{ display: 'none' }}
                      id="file-input-2"
                    />
                    <label htmlFor="file-input-2">
                      <Button fullWidth variant="contained" component="span">
                        Browse
                      </Button>
                    </label>
                  </FormControl>
                </Box>
                <Box>
                  {uploadSuccess ? (
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '4rem'
                      }}>
                      <IconButton color="success">
                        <CheckCircleIcon />
                      </IconButton>
                    </Box>
                  ) : uploadPDF ? (
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '4rem'
                      }}>
                      <CircularProgress size={20} />
                    </Box>
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={!selectedFile}>
                      Upload
                    </Button>
                  )}
                </Box>
              </Box>
            </Grid>
          </Grid>
          {selectedFile && (
            <Typography variant="body2" align="center">
              {selectedFile.name}
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
};
