import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import useIsLargeScreen from 'hooks/useIsLargeScreen';
import React, { useState } from 'react';

import { Box, Grid, Typography } from '@mui/material';

import AudioModal from 'components/Audios/AudioModal';
import ConfirmDeleteDialog from 'components/ConfirmDeleteDialog';

import { Colors } from 'design/theme';

import {
  deleteAdminAudio,
  getAudioProfiles,
  publishAudioProfile
} from 'redux/admin/admin.action';
import { adminSelector } from 'redux/admin/admin.selector';

import { AudioProfilesAdmins } from 'types/admin.requests';

import { AudioProfileCard } from './AudioProfileCard';
import { PublishAudioDialog } from './PublishAudioDialog';

export const AudioCabinet: React.FC = () => {
  const isLargeScreen = useIsLargeScreen();
  const dispatch = useAppDispatch();
  const audioProfiles =
    useAppSelector(adminSelector).audios.audioProfiles || [];
  const [playingUrl, setPlayingUrl] = useState<string | null>(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [currentAudioId, setCurrentAudioId] = useState<string | null>(null);
  const [currentAudioData, setCurrentAudioData] = useState<AudioProfilesAdmins>(
    {} as AudioProfilesAdmins
  );
  const [open, setOpen] = useState<boolean>(false);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);

  const togglePlayPause = (url: string) => {
    setPlayingUrl(playingUrl === url ? null : url);
  };
  const handleOpenDialog = (audioId: string) => {
    setCurrentAudioId(audioId);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const handleOpenDeleteModal = () => {
    setOpenDeleteModal(true);
  };

  const handleCloseDelete = () => {
    setOpen(false);
    setOpenDeleteModal(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenModal = (audio: AudioProfilesAdmins) => {
    setOpen(true);
    setCurrentAudioData(audio);
  };

  const handleDelete = async () => {
    const result = await dispatch(deleteAdminAudio(currentAudioData.id));
    if (deleteAdminAudio.fulfilled.match(result)) {
      dispatch(getAudioProfiles());
    }
  };

  const handleConfirmPublish = () => {
    if (currentAudioId) {
      dispatch(publishAudioProfile({ audioID: currentAudioId }));
      dispatch(getAudioProfiles());
      setOpenDialog(false);
    }
  };
  const sortedAudioProfiles = (audioProfiles || [])
    .map((profile) => ({ ...profile }))
    .sort((a, b) => {
      if (a.review === b.review) {
        return (
          new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
        );
      }
      return a.review ? 1 : -1;
    });

  if (sortedAudioProfiles && sortedAudioProfiles.length < 1) {
    return (
      <Box sx={{ height: '20rem' }}>
        <Box
          sx={{
            margin: 'auto',
            background: 'rgba(53, 101, 105, 0.3)',
            height: '8rem',
            display: 'flex',
            alignItems: 'center',
            borderRadius: '0.25rem',
            justifyContent: 'center',
            flexDirection: 'column'
          }}>
          <Typography
            fontFamily='"Nunito"'
            fontSize={'1.25rem'}
            color={Colors.bgGreen}
            sx={{
              width: '70%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '0.25rem',
              textAlign: 'center',
              gap: '1rem'
            }}>
            Try creating your first Audio Profile in Audio Lab
          </Typography>
        </Box>
      </Box>
    );
  }

  return (
    <Box sx={{ paddingLeft: '1rem', margin: 0, width: '100%' }}>
      <Typography
        fontWeight={500}
        fontFamily={'Nunito'}
        fontSize={'1.25rem'}
        sx={{
          color: Colors.bgGreen,
          marginTop: '0.5rem'
        }}>
        Audio Profiles Created
      </Typography>
      <Box>
        <Grid container spacing={2} mt={0}>
          {sortedAudioProfiles.map((audio) => (
            <Grid item xs={12} md={4} key={audio.id}>
              <AudioProfileCard
                audio={audio}
                isLargeScreen={isLargeScreen}
                isPlaying={playingUrl === audio.audioURL}
                onTogglePlay={() => togglePlayPause(audio.audioURL)}
                onOpenDialog={() => handleOpenDialog(audio.id)}
                handleOpenModal={handleOpenModal}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
      <AudioModal
        open={open}
        handleClose={handleClose}
        currentAudioData={currentAudioData}
        handleOpenDeleteModal={handleOpenDeleteModal}
      />
      <ConfirmDeleteDialog
        open={openDeleteModal}
        onClose={handleCloseDelete}
        onConfirm={handleDelete}
      />
      <PublishAudioDialog
        open={openDialog}
        onClose={handleCloseDialog}
        onConfirm={handleConfirmPublish}
      />
    </Box>
  );
};

export default AudioCabinet;
