import { createAsyncThunk } from '@reduxjs/toolkit';
import affiliationsService from 'services/affiliations';

export const getAllAffiliationsProcess = createAsyncThunk(
  'affiliations/getAllAffiliations',
  async () => {
    const response = await affiliationsService.getAllAffiliations();
    return response.data.data;
  }
);

export const updateStatusReviewProcess = createAsyncThunk(
  'affiliations/updateStatusReview',
  async ({
    id,
    statusReview,
    statusAffiliate
  }: {
    id: string;
    statusReview: boolean;
    statusAffiliate: boolean;
  }) => {
    const response = await affiliationsService.updateStatusReview(
      id,
      statusReview,
      statusAffiliate
    );
    return response.data.data;
  }
);

export const confirmCredentialsProcess = createAsyncThunk(
  'affiliations/confirmCredentials',
  async (confirmCredentials: {
    affiliateId: string;
    affiliateLinkCreation: boolean;
  }) => {
    const response = await affiliationsService.confirmCredentials(
      confirmCredentials
    );
    return response.data;
  }
);
