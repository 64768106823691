import React from 'react';

import { Box, Button, Card, CardContent, Typography } from '@mui/material';

import { Colors } from 'design/theme';

import { AffirmationProduct } from 'types/admin.requests';

interface AffirmationCardProps {
  affirmation: AffirmationProduct;
  isReview?: boolean;
  handleOpen: (
    affirmations: { id: number; affirmationSentence: string }[]
  ) => void;
  handleOpenDialog: (audioId: string, developmentArea: string) => void;
  handleOpenModal: (affirmation: AffirmationProduct) => void;
}

const AffirmationCard: React.FC<AffirmationCardProps> = ({
  affirmation,
  handleOpen,
  handleOpenDialog,
  isReview,
  handleOpenModal
}) => {
  return (
    <Card
      sx={{
        width: '100%',
        height: '100%',
        background: Colors.white,
        borderRadius: '0.25rem',
        display: 'flex',
        flexDirection: 'row',
        color: Colors.green.main,
        border: `1px solid ${Colors.lightGrey}`,
        borderBottom: `0.5rem solid ${Colors.green.light}`,
        '&:hover': {
          background: Colors.green.light,
          color: Colors.greenBlue
        },
        '&:active': {
          boxShadow: 'inset 0px 2px 0px 0px rgba(255, 255, 255, 0)',
          background: Colors.green.main,
          color: Colors.green.dark,
          borderBottom: `0.5rem solid ${Colors.greenBlue}`
        }
      }}
      onClick={() => handleOpen(affirmation.affirmations)}>
      <CardContent>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
            width: '100%',
            padding: '1rem'
          }}>
          <Typography
            fontFamily={'"Nunito"'}
            fontSize={'1rem'}
            fontWeight={400}>
            Title:
            <span style={{ fontWeight: 700 }}> {affirmation.title}</span>
          </Typography>
          <Typography
            fontFamily={'"Nunito"'}
            fontSize={'1rem'}
            fontWeight={400}
            sx={{ marginTop: '0.15rem', paddingBottom: '0.5rem' }}>
            Development Area:
            <span style={{ fontWeight: 700 }}>
              {' '}
              {affirmation.developmentArea}
            </span>
          </Typography>

          <Typography
            fontFamily='"Nunito"'
            fontSize="1rem"
            fontWeight={800}
            sx={{
              marginTop: '0.15rem',
              borderTop: '0.5px solid',
              paddingTop: '0.5rem',
              justifyContent: 'center'
            }}>
            Affirmation Parameters:
          </Typography>

          {affirmation.qualities.map((quality, qIndex) => (
            <Box
              key={qIndex}
              sx={{
                display: 'flex',
                margin: '1rem 0',
                flexDirection: 'column',
                textAlign: 'left',
                alignContent: 'left',
                gap: '0.25rem'
              }}>
              <Typography
                fontFamily={'"Nunito"'}
                fontSize={'1rem'}
                fontWeight={400}>
                Outcome:
                <span style={{ fontWeight: 700 }}> {affirmation.outcome}</span>
              </Typography>
              <Typography
                fontFamily={'"Nunito"'}
                fontSize={'1rem'}
                fontWeight={400}>
                Quality {String.fromCharCode(65 + qIndex)}:
                <span style={{ fontWeight: 700 }}>
                  {' '}
                  {quality.quality || quality.otherQuality}
                </span>
              </Typography>

              <Typography
                fontFamily={'"Nunito"'}
                fontSize={'1rem'}
                fontWeight={400}>
                Traits {String.fromCharCode(65 + qIndex)}:{' '}
                <span style={{ fontWeight: 700 }}>
                  {' '}
                  {quality.traits.map((trait) => trait.trait).join(', ')}
                </span>
              </Typography>
            </Box>
          ))}
          <Typography
            fontFamily='"Nunito"'
            fontSize="1rem"
            fontWeight={400}
            sx={{ marginTop: '0.15rem' }}>
            Published:{' '}
            <span style={{ fontWeight: 700 }}>
              {affirmation.Published ? 'True' : 'False'}
            </span>
          </Typography>
          <Typography
            fontFamily='"Nunito"'
            fontSize="1rem"
            fontWeight={400}
            sx={{ marginTop: '0.15rem' }}>
            In Review:{' '}
            <span style={{ fontWeight: 700 }}>
              {affirmation.review ? 'True' : 'False'}
            </span>
          </Typography>
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-around',
              alignContent: 'center',
              marginTop: '1rem'
            }}>
            <Button
              disabled={affirmation.Published && !isReview}
              variant="contained"
              onClick={(e) => {
                e.stopPropagation();
                handleOpenModal(affirmation);
              }}
              sx={{ minWidth: '5rem' }}>
              Edit
            </Button>
          </Box>
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-around',
              alignContent: 'center',
              marginTop: '1rem'
            }}>
            <Button
              disabled={isReview ? affirmation.Published : affirmation.review}
              variant="contained"
              onClick={(e) => {
                e.stopPropagation();
                handleOpenDialog(affirmation.id, affirmation.developmentArea);
              }}
              sx={{ minWidth: '5rem' }}>
              {isReview ? 'Accept' : 'Publish'}
            </Button>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default AffirmationCard;
