import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import React, { useState } from 'react';

import { Box, Grid, Typography } from '@mui/material';

import { Colors } from 'design/theme';

import {
  getCreatedAffirmations,
  publishAffirmationTemplate
} from 'redux/admin/admin.action';
import { adminSelector } from 'redux/admin/admin.selector';

import { AffirmationProduct } from 'types/admin.requests';

import AffirmationCard from './AffirmationCard';
import AffirmationsModal from './AffirmationsModal';
import EditAffirmationModal from './EditAffirmationModal';
import { PublishAffirmationTemplateDialog } from './PublishAffirmationTemplateDialog';

export const AffirmationsCabinet: React.FC = () => {
  const dispatch = useAppDispatch();
  const affirmationsCabinet = useAppSelector(adminSelector)
    .affirmationsCabinet as AffirmationProduct[];
  const [open, setOpen] = useState<boolean>(false);
  const [selectedAffirmations, setSelectedAffirmations] = useState<
    { id: number; affirmationSentence: string }[]
  >([]);

  const [openDialog, setOpenDialog] = useState(false);
  const [currentAffirmationId, setCurrentAffirmationId] = useState<
    string | null
  >(null);
  const [openModal, setOpenModal] = useState(false);
  const [currentAffirmationData, setCurrentAffirmationData] =
    useState<AffirmationProduct>({} as AffirmationProduct);

  const handleOpen = (
    affirmations: { id: number; affirmationSentence: string }[]
  ) => {
    setSelectedAffirmations(affirmations);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedAffirmations([]);
  };

  const handleOpenDialog = (audioId: string) => {
    setCurrentAffirmationId(audioId);
    setOpenDialog(true);
  };

  const handleCloseDialog = async () => {
    const result = await dispatch(getCreatedAffirmations());
    if (getCreatedAffirmations.fulfilled.match(result)) {
      setOpenDialog(false);
    }
  };

  const handleConfirmPublish = async () => {
    if (currentAffirmationId) {
      await dispatch(
        publishAffirmationTemplate({ affirmationID: currentAffirmationId })
      );
      const result = await dispatch(getCreatedAffirmations());
      if (getCreatedAffirmations.fulfilled.match(result)) {
        setOpenDialog(false);
      }
    }
  };

  const handleOpenModal = (affirmation: AffirmationProduct) => {
    setCurrentAffirmationData(affirmation);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const sortedAffirmationsCabinet = (affirmationsCabinet || [])
    .map((affirmation) => ({ ...affirmation }))
    .sort((a, b) => {
      if (a.Published === b.Published) {
        return a.review ? 1 : -1;
      }
      return a.Published ? 1 : -1;
    });

  if (sortedAffirmationsCabinet && sortedAffirmationsCabinet.length === 0) {
    return (
      <Box sx={{ height: '20rem' }}>
        <Box
          sx={{
            margin: 'auto',
            background: 'rgba(53, 101, 105, 0.3)',
            height: '8rem',
            display: 'flex',
            alignItems: 'center',
            borderRadius: '0.25rem',
            justifyContent: 'center',
            flexDirection: 'column'
          }}>
          <Typography
            fontFamily='"Nunito"'
            fontSize={'1.25rem'}
            color={Colors.bgGreen}
            sx={{
              width: '70%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '0.25rem',
              textAlign: 'center',
              gap: '1rem'
            }}>
            Try creating your first Affirmation Product in Affirmations Lab
          </Typography>
        </Box>
      </Box>
    );
  }

  return (
    <Box sx={{ paddingLeft: '1rem', margin: 0, width: '100%' }}>
      <Typography
        sx={{
          fontWeight: 500,
          color: Colors.bgGreen,
          fontFamily: '"Nunito"',
          fontSize: '1.25em',
          marginTop: '0.5rem'
        }}>
        Affirmations Created
      </Typography>
      <Grid container spacing={2} mt={0}>
        {sortedAffirmationsCabinet?.map((affirmation, index) => (
          <Grid item key={index} xs={12} md={4}>
            <AffirmationCard
              key={index}
              affirmation={affirmation}
              handleOpen={handleOpen}
              handleOpenDialog={handleOpenDialog}
              handleOpenModal={handleOpenModal}
            />
          </Grid>
        ))}
      </Grid>
      <EditAffirmationModal
        open={openModal}
        handleCloseModal={handleCloseModal}
        currentAffirmationData={currentAffirmationData}
      />
      <AffirmationsModal
        open={open}
        handleClose={handleClose}
        selectedAffirmations={selectedAffirmations}
      />
      <PublishAffirmationTemplateDialog
        open={openDialog}
        onClose={handleCloseDialog}
        onConfirm={handleConfirmPublish}
      />
    </Box>
  );
};
