import { useAppDispatch } from 'hooks/useAppDispatch';
import React, { useState } from 'react';

import { Box, Button, Container, Grid } from '@mui/material';

import { Colors } from 'design/theme';

import { getAllSeeds } from 'redux/admin/admin.action';

import { AffirmationSeed } from './AffirmationSeed';
import { MeditationSeed } from './MeditationSeed';
import { VisualizationSeed } from './VisualizationSeed';

enum TabOptions {
  AFFIRMATIONS = 'Affirmations',
  VISUALIZATIONS = 'Visualizations',
  MEDITATIONS = 'Guided Meditations'
}
const serviceEnv = process.env.SERVICE ?? 'PRODUCTION';
const Service = serviceEnv === 'STAGING';

export const SeedTab = () => {
  const [activeTab, setActiveTab] = useState<TabOptions>(
    TabOptions.AFFIRMATIONS
  );
  const dispatch = useAppDispatch();
  const handleTabClick = async (tabName: TabOptions) => {
    setActiveTab(tabName);

    await dispatch(getAllSeeds());
  };

  return (
    <Container
      sx={{
        margin: 'auto',
        justifyContent: 'center',
        pb: '2rem'
      }}>
      <Grid
        container
        spacing={1}
        sx={{ borderBottom: `1px solid ${Colors.grey}` }}>
        <Grid item>
          <Button
            sx={{
              fontFamily: '"Nunito"',
              fontSize: '1.15rem',
              borderBottom:
                activeTab === TabOptions.AFFIRMATIONS
                  ? `2px solid ${Colors.black}`
                  : '2px solid transparent',
              borderRadius: '0',
              color:
                activeTab === TabOptions.AFFIRMATIONS
                  ? Colors.black
                  : Colors.neutral,
              fontWeight: '500'
            }}
            onClick={() => handleTabClick(TabOptions.AFFIRMATIONS)}>
            {TabOptions.AFFIRMATIONS}
          </Button>
        </Grid>
        {Service && (
          <Grid item>
            <Button
              sx={{
                fontFamily: '"Nunito"',
                fontSize: '1.15rem',
                borderBottom:
                  activeTab === TabOptions.VISUALIZATIONS
                    ? `2px solid ${Colors.black}`
                    : '2px solid transparent',
                borderRadius: '0',
                color:
                  activeTab === TabOptions.VISUALIZATIONS
                    ? Colors.black
                    : Colors.neutral,
                fontWeight: '500'
              }}
              onClick={() => handleTabClick(TabOptions.VISUALIZATIONS)}>
              {TabOptions.VISUALIZATIONS}
            </Button>
          </Grid>
        )}
        {Service && (
          <Grid item>
            <Button
              sx={{
                fontFamily: '"Nunito"',
                fontSize: '1.15rem',
                borderBottom:
                  activeTab === TabOptions.MEDITATIONS
                    ? `2px solid ${Colors.black}`
                    : '2px solid transparent',
                borderRadius: '0',
                color:
                  activeTab === TabOptions.MEDITATIONS
                    ? Colors.black
                    : Colors.neutral,
                fontWeight: '500'
              }}
              onClick={() => handleTabClick(TabOptions.MEDITATIONS)}>
              {TabOptions.MEDITATIONS}
            </Button>
          </Grid>
        )}
      </Grid>
      <Box sx={{ marginTop: '1.5rem', padding: 0 }}>
        {activeTab === TabOptions.AFFIRMATIONS && (
          <>
            <AffirmationSeed />
          </>
        )}
        {activeTab === TabOptions.VISUALIZATIONS && (
          <>
            <VisualizationSeed />
          </>
        )}
        {activeTab === TabOptions.MEDITATIONS && (
          <>
            <MeditationSeed />
          </>
        )}
      </Box>
    </Container>
  );
};
