import { AdminHub } from 'pages/AdminHub';
import LoginPage from 'pages/Login';
import RequestRestorePassword from 'pages/RequestRestorePassword';
import RestorePassword from 'pages/RestorePassword';
import { Navigate, Route, Routes as RoutesFromRouter } from 'react-router-dom';

import FullScreen from 'design/Layouts/FullScreen';
import HalfFullScreenCentered from 'design/Layouts/HalfFullScreenCentered';

import RoutesEnum from 'types/routes.enum';

import ProtectedRoute from './ProtectRoute';

const Routes = () => (
  <RoutesFromRouter>
    <Route element={<HalfFullScreenCentered />}>
      <Route path={RoutesEnum.LOGIN} element={<LoginPage />} />
      <Route
        path={RoutesEnum.RESTORE_REQUEST}
        element={<RequestRestorePassword />}
      />
      <Route path={RoutesEnum.RESTORE_PASSWORD} element={<RestorePassword />} />
    </Route>

    <Route
      element={
        <ProtectedRoute>
          <FullScreen />
        </ProtectedRoute>
      }
      path={RoutesEnum.USER_HOME}>
      <Route path={RoutesEnum.USER_HOME} element={<AdminHub />} />
    </Route>

    <Route path="*" element={<Navigate to={RoutesEnum.LOGIN} />} />
  </RoutesFromRouter>
);
export default Routes;
