import { useAppDispatch } from 'hooks/useAppDispatch';
import useIsLargeScreen from 'hooks/useIsLargeScreen';
import React, { useState } from 'react';

import { Box, Button, Grid } from '@mui/material';

import { Colors } from 'design/theme';

import { getPromotionCodes } from 'redux/admin/admin.action';

import AllPromotions from './AllPromotions';
import { CreatePromotionCode } from './CreatePromotionCode';

export enum TabOptions {
  CREATE_PROMO = 'Create promotion code',
  ALL_PROMO = 'Promotion codes',
  CREATE_PROMO_MOBILE = 'Create code',
  ALL_PROMO_MOBILE = 'Promotions'
}

export const PromotionCode: React.FC = () => {
  const isLargeScreen = useIsLargeScreen();
  const dispatch = useAppDispatch();

  const [activeTab, setActiveTab] = useState<TabOptions>(TabOptions.ALL_PROMO);

  const handleTabClick = (tab: TabOptions) => {
    setActiveTab(tab);
    if (tab === TabOptions.ALL_PROMO) {
      dispatch(getPromotionCodes());
    }
  };

  return (
    <Box
      sx={{
        margin: 0,
        width: '100%'
      }}>
      <Grid
        container
        spacing={1}
        sx={{ borderBottom: `1px solid ${Colors.grey}` }}>
        <Grid item>
          <Button
            sx={{
              fontFamily: '"Nunito"',
              fontSize: '1.15rem',
              borderBottom:
                activeTab === TabOptions.ALL_PROMO
                  ? `2px solid ${Colors.black}`
                  : '2px solid transparent',
              borderRadius: '0',
              color:
                activeTab === TabOptions.ALL_PROMO
                  ? Colors.black
                  : Colors.neutral,
              fontWeight: '500'
            }}
            onClick={() => handleTabClick(TabOptions.ALL_PROMO)}>
            {isLargeScreen ? TabOptions.ALL_PROMO : TabOptions.ALL_PROMO_MOBILE}
          </Button>
          <Button
            sx={{
              fontFamily: '"Nunito"',
              fontSize: '1.15rem',
              borderBottom:
                activeTab === TabOptions.CREATE_PROMO
                  ? `2px solid ${Colors.black}`
                  : '2px solid transparent',
              borderRadius: '0',
              color:
                activeTab === TabOptions.CREATE_PROMO
                  ? Colors.black
                  : Colors.neutral,
              fontWeight: '500'
            }}
            onClick={() => handleTabClick(TabOptions.CREATE_PROMO)}>
            {isLargeScreen
              ? TabOptions.CREATE_PROMO
              : TabOptions.CREATE_PROMO_MOBILE}
          </Button>
        </Grid>
      </Grid>
      <Box sx={{ marginTop: '2rem', padding: 0 }}>
        {activeTab === TabOptions.ALL_PROMO && (
          <>
            <AllPromotions />
          </>
        )}
        {activeTab === TabOptions.CREATE_PROMO && (
          <>
            <CreatePromotionCode handleTabClick={handleTabClick} />
          </>
        )}
      </Box>
    </Box>
  );
};
