import React from 'react';

import { Grid, TextField } from '@mui/material';

import { Affiliation } from 'redux/affiliations/types/affiliations.interface';

interface RequesterDetailsModalProps {
  requester: Affiliation | null;
}

export const CredentialDetails: React.FC<RequesterDetailsModalProps> = ({
  requester
}) => {
  return (
    <Grid container spacing={2} mt={1}>
      {requester && (
        <>
          <Grid item xs={12} md={6}>
            <TextField
              label="Name"
              value={`${requester.user.firstName} ${requester.user.lastName}`}
              fullWidth
              InputProps={{
                readOnly: true
              }}
              variant="filled"
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              label="Email"
              value={requester.user.email}
              fullWidth
              InputProps={{
                readOnly: true
              }}
              variant="filled"
            />
          </Grid>

          <Grid item xs={12} md={requester.website ? 4 : 6}>
            <TextField
              label="Country"
              value={requester.user.country}
              fullWidth
              InputProps={{
                readOnly: true
              }}
              variant="filled"
            />
          </Grid>

          <Grid item xs={12} md={requester.website ? 4 : 6}>
            <TextField
              label="Phone Number"
              value={requester.phoneNumber}
              fullWidth
              InputProps={{
                readOnly: true
              }}
              variant="filled"
            />
          </Grid>

          {requester.website && (
            <Grid item xs={12} md={4}>
              <TextField
                label="Website"
                value={requester.website}
                fullWidth
                InputProps={{
                  readOnly: true
                }}
                variant="filled"
              />
            </Grid>
          )}

          <Grid item xs={12}>
            <TextField
              label="Content Type"
              value={requester.contentType}
              fullWidth
              InputProps={{
                readOnly: true
              }}
              variant="filled"
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              label="Audience"
              value={requester.audience}
              fullWidth
              InputProps={{
                readOnly: true
              }}
              variant="filled"
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              label="Recent Post"
              value={requester.recentPost}
              fullWidth
              InputProps={{
                readOnly: true
              }}
              variant="filled"
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              label="Status Review"
              value={requester.statusReview ? 'Accepted' : 'In Review'}
              fullWidth
              InputProps={{
                readOnly: true
              }}
              variant="filled"
            />
          </Grid>

          {requester.socials.length > 0 && (
            <>
              {requester.socials.map((social, index) => (
                <Grid
                  item
                  xs={12}
                  md={Math.max(12 / requester.socials.length, 4)}
                  key={index}>
                  <TextField
                    label={social.social}
                    value={social.url}
                    fullWidth
                    InputProps={{
                      readOnly: true
                    }}
                    variant="filled"
                  />
                </Grid>
              ))}
            </>
          )}
        </>
      )}
    </Grid>
  );
};

export default CredentialDetails;
