import { useAppSelector } from 'hooks/useAppSelector';
import React, { useState } from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Switch
} from '@mui/material';

import { Colors } from 'design/theme';

import { adminSelector } from 'redux/admin/admin.selector';

export interface AcceptAffirmationDialogProps {
  open: boolean;
  onClose: () => void;
  onConfirm: (status: boolean) => void;
  currentAffirmationDevArea: string;
  setAudioProfileData: React.Dispatch<React.SetStateAction<any>>;
}

export const AcceptAffirmationDialog: React.FC<
  AcceptAffirmationDialogProps
> = ({
  open,
  onClose,
  onConfirm,
  currentAffirmationDevArea,
  setAudioProfileData
}) => {
  const [status, setStatus] = useState(false);
  const [selectedProfile, setSelectedProfile] = useState<string>('');

  const { audios } = useAppSelector(adminSelector);

  const handleConfirm = () => {
    onConfirm(status);
  };
  const handleProfileChange = async (event: SelectChangeEvent<string>) => {
    const profileID = event.target.value;
    const audioData = audios.audioProfilesToReview?.filter((aud) => {
      return aud.id === profileID;
    });
    if (audioData) {
      const profileName = audioData[0]?.profileName;
      setSelectedProfile(profileName);
    }
    setAudioProfileData(audioData);
  };

  return (
    <Dialog open={open} onClose={() => onClose()}>
      <DialogTitle id="alert-dialog-title">
        {'Accept Publish Audio Profile?'}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Are you sure you want to publish this template?
        </DialogContentText>
        <FormControlLabel
          control={
            <Switch
              checked={status}
              onChange={(event) => setStatus(event.target.checked)}
            />
          }
          label={status ? 'Status: Accept' : 'Status: Reject'}
        />
        {currentAffirmationDevArea === 'Morning' && (
          <>
            {' '}
            <FormControl fullWidth sx={{ marginTop: '1rem' }}>
              <InputLabel>Audio Profile</InputLabel>
              <Select
                labelId="audioProfile-label"
                id="audioProfile-select"
                value={selectedProfile}
                onChange={handleProfileChange}
                displayEmpty
                renderValue={() => selectedProfile || 'Audio Profile'}
                label="Audio Profile"
                sx={{ color: Colors.neutralGrey }}>
                <MenuItem value="">
                  <em>Audio Profile</em>
                </MenuItem>
                {audios.audioProfilesToReview &&
                  currentAffirmationDevArea === 'Morning' &&
                  audios.audioProfilesToReview?.map((profile) => (
                    <MenuItem key={profile.id} value={profile.id}>
                      {profile.profileName || 'Unnamed Profile'}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()}>Cancel</Button>
        <Button onClick={handleConfirm} autoFocus>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};
