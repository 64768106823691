const formatDate = (dateString: string, isLargeScreen: boolean): string => {
  const date = new Date(dateString);

  if (isLargeScreen) {
    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    };
    return date.toLocaleDateString('en-UK', options).replace(/, /g, ' - ');
  } else {
    return date.toLocaleDateString('en-UK').replace(/\//g, '/');
  }
};

export default formatDate;
